import React from "react";
import Header from './../common/header';
import Sidebar from './../common/sidebar';
import Footer from './../common/footer';
import logoimage from "../assets/images/friendsup.png";

const adminLayout = (ChildComponent) => {
    class AdminLayout extends React.Component {
        constructor(props) {
            super(props);
            this.state = {
                pageLoaded: false,
                saveLeadClickEvent: ""
            };
        }
        componentDidMount() {
            setTimeout(() => {
                this.setState(() => ({
                    pageLoaded: true
                }))
            }, 1000);
        }
        renderHtml() {
            //if we remove this comment this code will create issue with menu submenu
            // if (!this.state.pageLoaded) {
            //     return <div className="loading-page">
            //         <div className="center">
            //             <img src={logoimage} width="155" height="28" className="preloadercss" />
            //         </div>
            //     </div>
            // }
            return <>
                {/* <div className="preloader">
                    <img src={logoimage}  width="155" height="28"  className="preloadercss" />
                </div> */}
                <div className="nk-app-root">
                    <div className="nk-main">
                        <Sidebar />
                        <div className="nk-wrap">
                            <Header />
                            <div className="nk-content">
                                <div className="container">
                                    <div className="nk-content-inner">
                                        <div className="nk-content-body">
                                            <ChildComponent {...this.props} />
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <Footer />
                        </div>
                    </div>
                </div>

            </>
        }

        addLeadModalFooterContent() {
            return <>
                <div >
                    <button onClick={(e) => this.setState(() => ({ saveLeadClickEvent: (Math.random() + 1).toString(36).substring(7) }))} className="btn btn-default low-height-btn">Add Lead</button>
                </div>
            </>;
        }

        handleParentData = (e) => {
            console.log(e);
        }

        render() {
            return <>
                {this.renderHtml()}
            </>
        }
    }

    return AdminLayout;
}

export default adminLayout;