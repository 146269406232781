import React from "react";
const authLayout = (ChildComponent) => {
    class AuthLayout extends React.Component {
        constructor(props){
            super(props);
    
            this.state = {};
        }

        render(){
            return <>
           
                            <ChildComponent {...this.props} />
                       
        </>
        }
    }

    return AuthLayout;
}

export default authLayout;