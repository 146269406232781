import React, { useState, useEffect, useCallback } from "react";
import adminLayout from "../hoc/adminLayout.js";
import Select2 from "react-select2-wrapper";
import "react-select2-wrapper/css/select2.css";
import emptyrecord from "../assets/images/dashboardhome.png";
import debounce from "lodash/debounce";
import baseURL from "../common/base-urls.js";
import axios from "axios";
import useSWR, { mutate } from "swr";
import { useLocation } from "react-router-dom";
const HomeScreen = () => {
    const location = useLocation();
    useEffect(() => {
        const currentTime = new Date();
        const formattedTime = currentTime.toLocaleTimeString([], { hour: '2-digit', minute: '2-digit' });
        //console.log(`=======================================`);
        //console.log(`========= Updated on ${formattedTime} =========`);
        //console.log(`=======================================`);
        //console.log(location);
        return () => {
        };
    }, [location]);
    return (
        <>
            <>
                <div className="home">
                    <span className="home-title">Welcome To <b>FriendsupAdmin</b>!</span>
                    <span className="home-desc">FriendsupAdmin Platform is now configured and ready to use! We hope you enjoy it!</span><img
                        className="home-img" src={emptyrecord} />
                </div>

            </>
        </>
    );
};
export default adminLayout(HomeScreen);
