import React, { useState, useEffect, useCallback } from "react";
import { Link } from 'react-router-dom';
import authLayout from "../hoc/authLayout";
import logoimage from "../assets/images/friendsup.png";
import storeimage from "../img/play1.png";
import faviconimg from "../images/favicon.png";
import axios from "axios";
import baseURL from "../common/base-urls.js";
import "react-select2-wrapper/css/select2.css";
import debounce from "lodash/debounce";
import useSWR, { mutate } from "swr";
import { useNavigate, useLocation } from "react-router-dom";

const Index = () => {
  const navigate = useNavigate();
  const pageData = useLocation();
  const [apiUrl, setApiUrl] = useState('');
  const [loading, setLoading] = useState(false); // Add loading state
  useEffect(() => {
    const currentTime = new Date();
    const formattedTime = currentTime.toLocaleTimeString([], { hour: '2-digit', minute: '2-digit' });
    //console.log(`=======================================`);
    //console.log(`========= Updated on ${formattedTime} =========`);
    //console.log(`=======================================`);
    //console.log(pageData);
    return () => {
    };
  }, [pageData]);
  //#region ====================================================== Create State =====================================================
  const [state, setState] = useState({
    show_toast: false,
    toast_msg: "No record found",
    islogin: false,
    fields: {
      email: "",
      password: "",
    },
    apifields: {
      email: "",
      password: "",
    },
    oldfields: {
      email: "",
      password: "",
    },
  });
  //#region ====================================================== Input Textbox ======================================================
  const handleInputChange = (event) => {
    const { name, value } = event.target;
    setState((prevState) => ({
      ...prevState,
      fields: {
        ...prevState.fields,
        [name]: value,
      },
    }));
  };
  //#region ====================================================== Update State ======================================================
  const updateState = (key, value) => {
    setState((prevState) => ({
      ...prevState,
      [key]: value,
    }));
  };
  const clearData = async () => {
    try {
      const updatedapifieldsState = {
        email: "",
        password: ""
      };
      const updatedfieldState = {
        email: "",
        password: ""
      };
      updateState("oldfields", state.apifields);
      updateState("apifields", updatedapifieldsState);
      updateState("fields", updatedfieldState);
    } catch (err) { }
  };
  //#region ====================================================== Toast Message set ======================================================
  const showToast = (message) => {
    updateState("show_toast", true);
    updateState("toast_msg", message);
    setTimeout(() => {
      updateState("show_toast", false);
    }, 5000);
  };
  const hideToast = () => {
    updateState("show_toast", false);
  };
  //#endregion

  const areObjectsEqual = (obj1, obj2) => {
    const keys1 = Object.keys(obj1);
    const keys2 = Object.keys(obj2);

    if (keys1.length !== keys2.length) {
      return false;
    }

    for (const key of keys1) {
      const val1 = obj1[key];
      const val2 = obj2[key];

      if (typeof val1 === 'object' && typeof val2 === 'object') {
        if (!areObjectsEqual(val1, val2)) {
          return false;
        }
      } else if (val1 !== val2) {
        return false;
      }
    }

    return true;
  };
  useEffect(() => {
    const saveData = async () => {
      if (state.islogin === true) {
        setLoading(true);
        try {
          if (!areObjectsEqual(state.oldfields, state.apifields)) {
            const newapiFields = { ...state.apifields };
            const dataforlogin = {
              "phone": newapiFields.email,
              "apptype": "ad",
              "password": newapiFields.password,
              "fcm_token": "dummyfcmtoken",
              "device_id": "dummydeviceid"
            };
            const result = await postAPI('admin/user/login', dataforlogin, false);
            if (result && result.statusCode === 201 && result.success === true) {
              const userData = result.data;
              localStorage.setItem('token', userData.token);
              localStorage.setItem('user_id', userData.user_id);
              localStorage.setItem('role_id', userData.role_id);
              localStorage.setItem('institute_id', userData.schoolid);
              localStorage.setItem('profile_small', userData.profile_small);
              localStorage.setItem('user_code', userData.user_code);
              localStorage.setItem('badge_color', userData.badge.badge_color);
              localStorage.setItem('username', userData.title + " " + userData.first_name + " " + userData.last_name);
              localStorage.setItem('user', JSON.stringify(userData));
              clearData();
              if (userData.role_id == "5" || userData.role_id == "6" || userData.role_id == "7" || userData.role_id == "1") {
                navigate("/bookslot");
              }
              else {
                showToast("Download Friendsup application from play store or app store. You can not login here.");
              }
              // else {
              //   navigate("/Home");
              // }
            } else {
              showToast("Incorrect credentials");
            }
          } else {
            showToast("Data has not changed. Please add unique records.");
          }
        } catch (err) {
          showToast(err);
          setLoading(false);
        }
        setLoading(false);
      }
      else {
        const token = localStorage.getItem('token');
        const role_id = localStorage.getItem('role_id');
        const user_id = localStorage.getItem('user_id');
        const institute_id = localStorage.getItem('institute_id');
        if (token && user_id && institute_id) {
          // if (role_id == "5" || role_id == "6") {
          navigate("/bookslot");
          // }
          // else {
          //   navigate("/Home");
          // }

        }
      }
    };
    saveData();
    updateState("islogin", false);
  }, [state.islogin]);
  const saveClicked = () => {
    if (state.fields.email.length == 6 && state.fields.password.length > 5) {
      setState((prevState) => ({
        ...prevState,
        islogin: true,
        apifields: {
          ...prevState.apifields,
          email: prevState.fields.email,
          password: prevState.fields.password
        },
      }));
    }
    else {
      showToast("Invalid credentials");
    }
    window.scrollTo({ top: 0, behavior: 'smooth' });
  };
  const debouncedSaveClicked = debounce(saveClicked, 500);
  const handleInputChangeNumber = (event) => {
    const { name, value } = event.target;
    // Remove non-digit characters from the input value
    const newValue = value.replace(/\D/g, '');
    setState((prevState) => ({
      ...prevState,
      fields: {
        ...prevState.fields,
        [name]: newValue,
      },
    }));
  };
  const forgotClicked = () => {
    window.scrollTo({ top: 0, behavior: 'smooth' });
    navigate("/forgotpassword", {});
  };


  //#region ====================================================== Post API ======================================================
  const postAPI = async (url, data, Auth) => {
    try {
      const response = await axios.post(`${baseURL.serverUrl}${url}`, data, {
        headers: {
          "x-access-token": '',
          'Content-Type': 'application/json',
        },
      });
      return response.data;
    } catch (err) {
      return {};
    }
  };
  //#endregion 

  useEffect(() => {
    const handleKeyDown = (event) => {
      if (event.key === 'Enter') {
        event.preventDefault(); // Prevent any default action, if necessary
        saveClicked();
      }
    };

    // Add the event listener when the component mounts
    document.addEventListener('keydown', handleKeyDown);

    // Clean up the event listener when the component unmounts
    return () => {
      document.removeEventListener('keydown', handleKeyDown);
    };
  }, []);

  return (
    <>
      <style>
        {`
      
      @media (max-width: 999px) {
            .container {
                max-width: fit-content !important;
            }
                .divcenter{
                
    text-align: -webkit-center;
    }
    .intro .heading-wrapper{
    
    width: 100%;
    }
    .intro .product-wrapper {
    position: relative;
    display: block;
    width: 100%;
}
    .form-control{
    font-size: 2rem;
    }
    .btn-custom{
    
    font-size: 2rem !important;
    }
    .form-group-custom{
        height: 75px;}
    .intro .heading {
    
    font-size: 8rem;
}
    .intro .subtitle {
    font-size: 2rem !important;
}
    .footer-info .contact-number {
    font-size: 2rem !important;
     
}
        .intro .product-wrapper img {
        width: 300px;
    }
    .table-custom{ 
    width: 100% !important;
    max-width: 100% !important;}
        }
        `}
      </style>
      <main>
        <div className="divcenter">
          <header className="menu-header">
            <div className="container">
              <nav className="navbar navbar-dark small navbar-expand-lg navbar-custom">
                <a className="navbar-brand navbar-brand-custom" >
                  <img src={logoimage} className="friendsup-logo" alt="Friendsup logo" />
                </a>
                <button className="navbar-toggler navbar-toggler-custom" type="button" data-toggle="collapse" data-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
                  <span className="navbar-toggler-icon"></span>
                </button>
                <div className="collapse navbar-collapse" id="navbarSupportedContent">
                  <ul className="navbar-nav ml-auto">
                    <li className="nav-item active">
                      <a className="nav-link nav-link-custom" >Home</a>
                    </li>
                    <li className="nav-item">
                      <a className="nav-link nav-link-custom">About</a>
                    </li>
                    <li className="nav-item">
                      <a className="nav-link nav-link-custom">Features</a>
                    </li>
                    <li className="nav-item">
                      <a className="nav-link nav-link-custom">Support</a>
                    </li>
                    <li className="nav-item ml-sm-2">
                      <a href="tel:9574749501" className="btn-round nav-link btn btn-dark btn-sm btn-contact">Contact Now <i className="ri-phone-line ri-center"></i>
                      </a>
                    </li>
                  </ul>
                </div>
              </nav>
            </div>
          </header>

          <section className="intro mt-5 py-5">
            <div className="container">
              <div className="friendsupw">
                <div className="heading-wrapper">
                  <h1 className="display-1 font-weight-bold heading">Friendsup</h1>
                  <p className="lead subtitle">
                    Revolutionizing the way teachers, parents, and students communicate for better education
                  </p>
                </div>
              </div>
              <div className="row mt-5">
                <div className="col-sm-12 text-center">
                  <div className="product-wrapper">
                    <table className="table table-custom">
                      <tr>
                        <td>

                          <div className="form-group form-group-custom">
                            <input type="text" name="email" maxLength={6} className="form-control form-control-custom" id="txtemail" placeholder="Enter 6 digit ID"
                              value={state.fields.email}
                              onChange={handleInputChangeNumber} />
                          </div>

                        </td>
                      </tr>
                      <tr>
                        <td>
                          <div className="form-group form-group-custom">
                            <input name="password" type="password" className="form-control form-control-custom" id="txtpassword" placeholder="Enter password" value={state.fields.password}
                              onChange={handleInputChange} />
                          </div>

                        </td>
                      </tr>
                      <tr>
                        <td>
                          <div className="mt-5px">

                            {/* <asp:Label ID="lblloginmsg" className="label-error" Text="" runat="server" /> */}

                            <button type="button" className="btn btn-custom btn-block btn-customindex"
                              id="btnlogin" onClick={saveClicked}> {loading ? 'Authenticating...' : 'Sign In'}</button>


                          </div>
                        </td>
                      </tr>
                    </table>
                    <div className="mt-5">
                      <a className="application-button">
                        <img src={storeimage} alt="Download App" />
                      </a>
                    </div>
                  </div>
                </div>
              </div>
              <div className="footer-info">
                <div className="friendsupw">
                  <div className="contact-info">
                    <p className="lead subtitle contactno">
                      For any queries
                    </p>
                    <p className="lead contact-number">
                      95 74 74 95 01
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </section>

        </div>
      </main >


      {/* ====================================================== Toast message ====================================================== */}
      {
        state.show_toast && (
          <div
            className="toast align-items-center text-bg-primary border-0 fade show toastmsg"
            role="alert"
            aria-live="assertive"
            aria-atomic="true"
          >
            <div className="d-flex">
              <div className="toast-body">{state.toast_msg}</div>
              <button
                type="button"
                className="btn-close btn-close-white me-2 m-auto"
                data-bs-dismiss="toast"
                onClick={(e) => hideToast()}
                aria-label="Close"
              ></button>
            </div>
          </div>
        )
      }


    </>
  );
};

export default authLayout(Index);
