import React, { useState, useEffect, useCallback } from "react";
import adminLayout from "../hoc/adminLayout.js";
import Select2 from "react-select2-wrapper";
import "react-select2-wrapper/css/select2.css";
import debounce from "lodash/debounce";
import baseURL from "../common/base-urls.js";
import axios from "axios";
import useSWR, { mutate } from "swr";

import Resizer from 'react-image-file-resizer';
import AWS from 'aws-sdk';
import { Buffer } from 'buffer';

import { useNavigate, useLocation } from "react-router-dom";
import baseUrls from "../common/base-urls.js";
const CourseEntry = () => {
    const navigate = useNavigate();
    const pageData = useLocation();
    const [apiUrl, setApiUrl] = useState('');
    useEffect(() => {
        const currentTime = new Date();
        const formattedTime = currentTime.toLocaleTimeString([], { hour: '2-digit', minute: '2-digit' });
        //console.log(`=======================================`);
        //console.log(`========= Updated on ${formattedTime} =========`);
        //console.log(`=======================================`);
        //console.log(pageData);
        return () => {
        };
    }, [pageData]);

    const [images, setImages] = useState([]);
    const [oldimages, setOldImages] = useState([]);
    const [imageFiles, setImageFiles] = useState([]);
    //#region ====================================================== Create State =====================================================
    const [state, setState] = useState({
        show_toast: false,
        toast_msg: "No record found",
        show_modal: false,
        saved: false,
        is_edit: false,
        data_assigned: false,
        modal_title: "Confirmation",
        modal_msg:
            "Are you absolutely certain you want to delete this record? This action cannot be reversed.",
        modal_button: "Delete",
        chkall: true,
        write_status: true,
        view_status: true,
        update_status: true,
        delete_status: true,
        fields: {
            course_type_id: "0",
            institute_id: "1",
            title: "",
            benefits_point: "",
            tagline: "",
            course_name: "",
            course_short: "",
            description: "",
            key_points: "",
            notes: "",
            p_amount: "0",
            s_amount: "0",
            g3_amount: "0",
            g6_amount: "0",
            g9_amount: "0",
            g12_amount: "0",
            s3_amount: "0",
            s6_amount: "0",
            s9_amount: "0",
            s12_amount: "0",
            max_coupon: "1",
            d_amount: "0",
            coupon_code: "",
            teacher_id: "",
            is_active: true,
            is_free: false,
            photos: "",
            oldphotos: ""

        },
        apifields: {
            course_type_id: "0",
            institute_id: "1",
            title: "",
            benefits_point: "",
            tagline: "",
            course_name: "",
            course_short: "",
            description: "",
            key_points: "",
            notes: "",
            p_amount: "0",
            s_amount: "0",
            g3_amount: "0",
            g6_amount: "0",
            g9_amount: "0",
            g12_amount: "0",
            s3_amount: "0",
            s6_amount: "0",
            s9_amount: "0",
            s12_amount: "0",
            max_coupon: "1",
            d_amount: "0",
            coupon_code: "",
            teacher_id: "",
            is_active: true,
            is_free: false,
            photos: "",
            oldphotos: ""
        },
        oldfields: {
            course_type_id: "0",
            institute_id: "1",
            title: "",
            benefits_point: "",
            tagline: "",
            course_name: "",
            course_short: "",
            description: "",
            key_points: "",
            notes: "",
            p_amount: "0",
            s_amount: "0",
            g3_amount: "0",
            g6_amount: "0",
            g9_amount: "0",
            g12_amount: "0",
            s3_amount: "0",
            s6_amount: "0",
            s9_amount: "0",
            s12_amount: "0",
            max_coupon: "1",
            d_amount: "0",
            coupon_code: "",
            teacher_id: "",
            is_active: true,
            is_free: false,
            photos: "",
            oldphotos: ""
        },
    });

    const [error, setError] = useState(null);
    //#endregion

    const handleImageChange = (e) => {
        setError(null);  // Reset error
        const files = Array.from(e.target.files);
        const validExtensions = ['image/jpeg', 'image/jpg', 'image/png'];
        const compressedImages = [];
        const originalFiles = [];

        files.forEach((file) => {
            if (!validExtensions.includes(file.type)) {
                setError('Only jpg, jpeg, and png files are allowed.');
                return;
            }

            const fileExtension = file.type.split('/')[1].toUpperCase();
            Resizer.imageFileResizer(
                file,
                800,
                800,
                fileExtension,
                70,
                0,
                (uri) => {
                    compressedImages.push(uri);
                    originalFiles.push(file);
                    if (compressedImages.length === files.length) {
                        setImages(compressedImages);
                        setImageFiles(originalFiles);
                    }
                },
                "base64"
            );
        });
    };


    const handleImageUpload = async () => {
        try {
            const extensions = imageFiles.map(file => `.${file.type.split('/')[1]}`).join(',');
            const response = await axios.get(`${baseURL.serverUrl}s3url/large?extension=${extensions}`);
            const { large: urls } = response.data.data;
            const promises = urls.map((url, index) => {
                const buffer = Buffer.from(images[index].replace(/^data:image\/\w+;base64,/, ""), 'base64');
                const fileExtension = imageFiles[index].type.split('/')[1];
                const mimeType = `image/${fileExtension === 'jpg' ? 'jpeg' : fileExtension}`;
                return axios.put(url, buffer, {
                    headers: {
                        'Content-Encoding': 'base64',
                        'Content-Type': mimeType
                    }
                });
            });

            await Promise.all(promises);

            const photoUrls = urls.map(url => url.split('?')[0]);
            // setState(prevState => ({
            //     ...prevState,
            //     fields: {
            //         ...prevState.fields,
            //         photos: photoUrls.join(',')
            //     }
            // }));
            // Assuming you're within a React component and using setState
            return photoUrls;

        } catch (error) {
            console.error("Error uploading images:", error);
        }
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        await handleImageUpload();
        // Submit the rest of the form data along with the photo URLs
    };



    //#region ====================================================== Shortcuts =====================================================
    document.addEventListener('keydown', function (event) {
        // Check if the key combination is Ctrl + S
        if (event.ctrlKey && event.key === 's') {
            // Prevent the default browser save behavior
            event.preventDefault();
            // Trigger the click event on your save button
            var saveButton = document.getElementById('btnsave');
            if (saveButton) {
                saveButton.click();
            }
        }
    });
    //#endregion
    //#region ====================================================== Update State ======================================================
    const updateState = (key, value) => {
        setState((prevState) => ({
            ...prevState,
            [key]: value,
        }));
    };
    //#endregion
    //#region ====================================================== Page Rights ======================================================
    const rightsURL = `user/chkuserrights?form_id=${3}`;
    const {
        data: tblhasrights,
        error: error1,
        isValidating: isValidating2,
    } = useSWR(rightsURL, () => getAPI(rightsURL, true), {
        revalidateOnReconnect: false,
        revalidateOnFocus: false,
    });
    //#endregion
    //#region ====================================================== coursetype Dropdown Bind ======================================================
    let tblcoursetypes = {};
    let apicoursetype = `coursetype?page=1&limit=50`;
    const { data: coursetypedata, errorcoursetype, isValidatingcoursetype } = useSWR(
        apicoursetype,
        () => getAPI(apicoursetype, false),
        {
            revalidateOnReconnect: false,
            revalidateOnFocus: false,
        }
    );
    tblcoursetypes = coursetypedata;
    //#endregion
    //#region ====================================================== user Dropdown Bind ======================================================
    let tblusers = {};
    let apiuser = `user/dropdown?page=1&limit=500`;
    const { data: userdata, erroruser, isValidatinguser } = useSWR(
        apiuser,
        () => getAPI(apiuser, false),
        {
            revalidateOnReconnect: false,
            revalidateOnFocus: false,
        }
    );
    tblusers = userdata;
    //#endregion
    //#region ====================================================== Create or Edit Record ======================================================
    let tbldata = {};
    useEffect(() => {
        if (
            tblhasrights &&
            tblhasrights.data &&
            Array.isArray(tblhasrights.data.hasrights) &&
            tblhasrights.data.hasrights.length > 0 &&
            tblhasrights.data.hasrights[0].hasOwnProperty('update_status') &&
            tblhasrights.data.hasrights[0]['update_status']
        ) {
            if (pageData.state && state.is_edit == false && state.data_assigned == false) {
                //console.log("inside get edit")
                const getData = async () => {
                    try {
                        const result = await getAPI(`course/${pageData.state.edit_id}`, false);
                        if (result && result.statusCode === 200 && result.success === true) {
                            tbldata = result;
                            ////console.log(tbldata)
                            const defaultImageUrls = tbldata.data.course_photos.map(photo => `${baseUrls.serverImageUrl}${photo.photo_name}`);
                            setOldImages(defaultImageUrls);
                            setState((prevState) => {
                                return {
                                    ...prevState,
                                    fields: {
                                        ...prevState.fields,
                                        course_type_id: tbldata.data.course_type_id,
                                        institute_id: tbldata.data.institute_id,
                                        title: tbldata.data.title,
                                        benefits_point: tbldata.data.benefits_point,
                                        tagline: tbldata.data.tagline,
                                        course_name: tbldata.data.course_name,
                                        course_short: tbldata.data.course_short,
                                        description: tbldata.data.description,
                                        key_points: tbldata.data.key_points,
                                        notes: tbldata.data.notes,
                                        p_amount: tbldata.data.p_amount,
                                        s_amount: tbldata.data.s_amount,
                                        g3_amount: tbldata.data.g3_amount,
                                        g6_amount: tbldata.data.g6_amount,
                                        g9_amount: tbldata.data.g9_amount,
                                        g12_amount: tbldata.data.g12_amount,

                                        s3_amount: tbldata.data.s3_amount,
                                        s6_amount: tbldata.data.s6_amount,
                                        s9_amount: tbldata.data.s9_amount,
                                        s12_amount: tbldata.data.s12_amount,
                                        max_coupon: tbldata.data.max_coupon,
                                        d_amount: tbldata.data.d_amount,
                                        coupon_code: tbldata.data.coupon_code,

                                        teacher_id: (Array.isArray(tbldata.data.course_teachers) && tbldata.data.course_teachers.length > 0)
                                            ? tbldata.data.course_teachers.map(teacher => teacher.user_id)
                                            : "",
                                        is_active: tbldata.data.is_active,
                                        is_free: tbldata.data.is_free,
                                    },
                                };
                            });

                            updateState("data_assigned", true);
                        } else {
                            showToast("Something went wrong. Please try again later.");
                        }
                    } catch (err) {
                    }
                };
                getData();
                updateState("is_edit", true);
            }
        }
    }, [tblhasrights, pageData]);
    //#endregion   
    //#region ====================================================== Input Textbox ======================================================
    const handleInputChange = (event) => {
        const { name, value } = event.target;
        setState((prevState) => ({
            ...prevState,
            fields: {
                ...prevState.fields,
                [name]: value,
            },
        }));
    };

    const handleInputChangekey_points = (event) => {
        const { name, value } = event.target;
        // Validate if the entered value is a number and has a length of 10 or less
        if (/^\d{0,10}$/.test(value)) {
            setState((prevState) => ({
                ...prevState,
                fields: {
                    ...prevState.fields,
                    [name]: value,
                },
            }));
        }
    };

    // ...


    //#endregion
    //#region ====================================================== Checkbox Handler ======================================================
    const handleCheckboxClick = (fieldName) => {
        setState((prevState) => ({
            ...prevState,
            fields: {
                ...prevState.fields,
                [fieldName]: !prevState.fields[fieldName],
            },
        }));
    };
    //#endregion
    //#region ====================================================== Multi Select2 Dropdown ======================================================
    const handleSelectMultipleChange = (event) => {
        const selectedOptions = Array.from(
            event.target.selectedOptions,
            (option) => option.value
        );
        setState((prevState) => ({
            ...prevState,
            fields: {
                ...prevState.fields,
                [event.target.name]: selectedOptions,
            },
        }));
    };
    //#endregion

    //#region ====================================================== Date validation ======================================================
    const handleDateChange = (event) => {
        const { name, value } = event.target;
        const { start_date, end_date } = state.fields;
        const isValidDate = /^\d{4}-\d{2}-\d{2}$/.test(value);
        if (!isValidDate) {
            showToast("Invalid date format");
            return;
        }
        if (name === "start_date" && new Date(value) > new Date(end_date)) {
            showToast("Start date cannot be greater than end date");
            return;
        }
        if (name === "end_date" && new Date(value) < new Date(start_date)) {
            showToast("End date cannot be less than start date");
            return;
        }
        // Update the state
        setState((prevState) => ({
            ...prevState,
            fields: { ...prevState.fields, [name]: value },
        }));
    };
    //#endregion
    //#region ====================================================== Checkbox Handler ======================================================
    const handleCheckboxActiveClick = () => {
        setState((prevState) => ({
            ...prevState,
            fields: {
                ...prevState.fields,
                is_active: !prevState.fields.is_active,
            },
        }));
    };
    //#endregion
    //#region ====================================================== Clear or Create/Edit Record ======================================================
    const areObjectsEqual = (obj1, obj2) => {
        const keys1 = Object.keys(obj1);
        const keys2 = Object.keys(obj2);

        if (keys1.length !== keys2.length) {
            return false;
        }

        for (const key of keys1) {
            const val1 = obj1[key];
            const val2 = obj2[key];

            if (typeof val1 === 'object' && typeof val2 === 'object') {
                if (!areObjectsEqual(val1, val2)) {
                    return false;
                }
            } else if (val1 !== val2) {
                return false;
            }
        }

        return true;
    };
    useEffect(() => {
        if (state.saved === true) {
            //console.log(state);
            if (state.is_edit === false) {
                const saveData = async () => {
                    try {
                        if (!areObjectsEqual(state.oldfields, state.apifields)) {
                            //  const newapiFields = state.apifields;
                            document.getElementById('fileInput').value = '';
                            const newapiFields = { ...state.apifields };

                            const result = await postAPI('course', newapiFields, false);

                            if (result && result.statusCode === 201 && result.success === true) {
                                showToast("Record has been added successfully.");
                                clearData();
                            } else {
                                showToast("Something went wrong. Please try again later.");
                            }
                        } else {
                            showToast("Data has not changed. Please add unique records.");
                        }
                    } catch (err) {
                    }
                };
                saveData();
                updateState("saved", false);
            }
            else {
                //console.log("inside edit");
                const editData = async () => {
                    try {
                        //console.log("state.apifields");
                        //console.log(state.apifields);
                        const newapiFields = { ...state.apifields };
                        const result = await putAPI('course', newapiFields, false);
                        if (result && result.statusCode === 200 && result.success === true) {
                            showToast("Record has been updated successfully.");
                            clearData();
                            updateState("saved", false);
                            updateState("is_edit", false);
                            navigate("/courselist");
                        } else {
                            showToast("Something went wrong. Please try again later.");
                        }
                    } catch (err) {

                    }
                };
                editData();


            }
        }
    }, [state.saved]);
    const saveClicked = () => {
        //console.log("state.apifields")

        if (
            state.fields.course_name &&
            state.fields.course_short &&
            state.fields.description &&
            state.fields.course_type_id && state.fields.course_type_id != "0"
        ) {
            let photouirl = "";
            const uploadPhotos = async () => {
                try {
                    photouirl = await handleImageUpload();
                    setState((prevState) => ({
                        ...prevState,
                        saved: false
                    }));
                    setState((prevState) => ({
                        ...prevState,
                        saved: true,
                        apifields: {
                            ...prevState.apifields,
                            course_type_id: prevState.fields.course_type_id,
                            institute_id: prevState.fields.institute_id,
                            benefits_point: prevState.fields.benefits_point,
                            tagline: prevState.fields.tagline,
                            course_name: prevState.fields.course_name,
                            course_short: prevState.fields.course_short,
                            description: prevState.fields.description,
                            key_points: prevState.fields.key_points,
                            is_active: prevState.fields.is_active,
                            is_free: prevState.fields.is_free,
                            notes: prevState.fields.notes,
                            p_amount: prevState.fields.p_amount,
                            s_amount: prevState.fields.s_amount,
                            g3_amount: prevState.fields.g3_amount,
                            g6_amount: prevState.fields.g6_amount,
                            g9_amount: prevState.fields.g9_amount,
                            g12_amount: prevState.fields.g12_amount,
                            s3_amount: prevState.fields.s3_amount,
                            s6_amount: prevState.fields.s6_amount,
                            s9_amount: prevState.fields.s9_amount,
                            s12_amount: prevState.fields.s12_amount,

                            max_coupon: prevState.fields.max_coupon,
                            d_amount: prevState.fields.d_amount,
                            coupon_code: prevState.fields.coupon_code,
                            teacher_id: prevState.fields.teacher_id,
                            photos: photouirl,
                            oldphotos: oldimages
                        },
                    }));
                } catch (err) {
                }
            };
            uploadPhotos();
            //console.log(photouirl);


        }
        else {
            if (!state.fields.course_name) {
                showToast("Course title cannot be empty.");
            }
            else
                if (!state.fields.course_short) {
                    showToast("Course short title cannot be empty.");
                }
                else if (!state.fields.description) {
                    showToast("Description cannot be empty.");
                }
                else if (!state.fields.course_type_id || state.fields.course_type_id === "0") {
                    showToast("Please select a valid course type.");
                }
        }
        window.scrollTo({ top: 0, behavior: 'smooth' });
    };
    const debouncedSaveClicked = debounce(saveClicked, 500);
    const clearData = async () => {
        try {
            let role_id = 2;
            let getUser = localStorage.getItem("user");
            if (getUser) {
                getUser = JSON.parse(getUser);
                role_id = getUser.role_id;
            }
            const updatedapifieldsState = {
                course_type_id: "0",
                institute_id: "1",
                title: "",
                benefits_point: "",
                tagline: "",
                course_name: "",
                course_short: "",
                description: "",
                key_points: "",
                notes: "",
                p_amount: "0",
                s_amount: "0",
                g3_amount: "0",
                g6_amount: "0",
                g9_amount: "0",
                g12_amount: "0",
                s3_amount: "0",
                s6_amount: "0",
                s9_amount: "0",
                s12_amount: "0",
                max_coupon: "1",
                d_amount: "0",
                coupon_code: "",
                teacher_id: "",
                is_active: true,
                is_free: false,
                photos: "",
                oldphotos: ""
            };
            const updatedfieldState = {

                course_type_id: "0",
                institute_id: "1",
                title: "",
                benefits_point: "",
                tagline: "",
                course_name: "",
                course_short: "",
                description: "",
                key_points: "",
                notes: "",
                p_amount: "0",
                s_amount: "0",
                g3_amount: "0",
                g6_amount: "0",
                g9_amount: "0",
                g12_amount: "0",
                s3_amount: "0",
                s6_amount: "0",
                s9_amount: "0",
                s12_amount: "0",
                max_coupon: "1",
                d_amount: "0",
                coupon_code: "",
                teacher_id: "",
                is_active: true,
                is_free: false,
                photos: "",
                oldphotos: ""
            };
            updateState("oldfields", state.apifields);
            updateState("apifields", updatedapifieldsState);
            updateState("fields", updatedfieldState);
            setImages([]);
            setOldImages([]);



        } catch (err) { }
    };
    //#endregion
    //#region ====================================================== Back or FAQ ======================================================
    const Back = () => {
        try {

            window.scrollTo({ top: 0, behavior: 'smooth' });
            navigate("/courselist");
        } catch (err) { }
    };
    const debouncedBack = debounce(Back, 500);
    const FAQ = () => {
        try {

            window.scrollTo({ top: 0, behavior: 'smooth' });
            navigate("/FAQ");
        } catch (err) { }
    };
    const debouncedFAQ = debounce(FAQ, 500);
    //#endregion
    //#region ====================================================== Toast Message set ======================================================
    const showToast = (message) => {
        updateState("show_toast", true);
        updateState("toast_msg", message);
        setTimeout(() => {
            updateState("show_toast", false);
        }, 5000);
    };
    const hideToast = () => {
        updateState("show_toast", false);
    };
    //#endregion






    //#region ====================================================== Get API ======================================================
    const getAPI = async (url, Auth) => {
        try {
            let getUser = localStorage.getItem("user");
            if (getUser) {
                getUser = JSON.parse(getUser);
                if (Auth) {
                    url += `&user_id=${getUser.user_id}`;
                }
                //console.log("inside GEt")
                //console.log(url)
                const response = await axios.get(`${baseURL.serverUrl}${url}`, {
                    headers: {
                        "x-access-token": getUser.token,
                        Authorization: `Bearer ${getUser.token}`,
                    },
                });

                //console.log(response.data)
                return response.data;
            } else {
                return {};
            }
        } catch (err) {
            return {};
        }
    };
    //#endregion
    //#region ====================================================== Post API ======================================================
    const postAPI = async (url, data, Auth) => {
        try {
            let getUser = localStorage.getItem("user");
            if (getUser) {
                getUser = JSON.parse(getUser);
                if (Auth) {
                    // Pass user_id through data
                    data.user_id = getUser.user_id;
                }
                //console.log("inside Post data");
                //console.log(data);
                const response = await axios.post(`${baseURL.serverUrl}${url}`, data, {
                    headers: {
                        "x-access-token": getUser.token,
                        Authorization: `Bearer ${getUser.token}`,
                        'Content-Type': 'application/json',
                    },
                });
                return response.data;
            } else {
                return {};
            }
        } catch (err) {
            return {};
        }
    };
    //#endregion 
    //#region ====================================================== Post API ======================================================
    const putAPI = async (url, data, Auth) => {
        try {

            let getUser = localStorage.getItem("user");
            if (getUser) {
                getUser = JSON.parse(getUser);
                if (Auth) {
                    data.user_id = getUser.user_id;
                }
                //console.log("inside put data");
                //console.log(data);
                const response = await axios.put(`${baseURL.serverUrl}${url}/${pageData.state.edit_id}`, data, {
                    headers: {
                        "x-access-token": getUser.token,
                        Authorization: `Bearer ${getUser.token}`,
                        'Content-Type': 'application/json',
                    },
                });
                //console.log(response)
                return response.data;
            } else {
                return {};
            }
        } catch (err) {
            return {};
        }
    };
    //#endregion 
    const removeImage = (indexToRemove) => {
        setImages(images.filter((_, index) => index !== indexToRemove));
        setImageFiles(imageFiles.filter((_, index) => index !== indexToRemove));
        //console.log(images)
    };
    const removeOldImage = (indexToRemove) => {
        setOldImages(oldimages.filter((_, index) => index !== indexToRemove));

    };
    return (
        <>
            <>
                {/* ====================================================== Head Section ====================================================== */}
                <div className="nk-block-head">
                    <div className="nk-block-head-between flex-wrap gap g-2">
                        {/* ====================================================== Page Name ====================================================== */}
                        <div className="nk-block-head-content">
                            <h2 className="nk-block-title">Course</h2>
                            <nav>
                                <ol className="breadcrumb breadcrumb-arrow mb-0">
                                    <li className="breadcrumb-item">
                                        <a href="#">Home</a>
                                    </li>
                                    <li className="breadcrumb-item">
                                        <a href="#">Course</a>
                                    </li>
                                    <li className="breadcrumb-item active" aria-current="page">
                                        Entry
                                    </li>
                                </ol>
                            </nav>
                        </div>
                        {/* ====================================================== Add New record filter export ====================================================== */}
                        <div className="nk-block-head-content">
                            <ul className="d-flex">
                                {tblhasrights &&
                                    tblhasrights.data &&
                                    Array.isArray(tblhasrights.data.hasrights) &&
                                    tblhasrights.data.hasrights.length > 0 &&
                                    tblhasrights.data.hasrights[0].hasOwnProperty("view_status") &&
                                    tblhasrights.data.hasrights[0]["view_status"] && (
                                        <>
                                            {!state.show_filter && (
                                                <>
                                                    <li>
                                                        <a
                                                            className="btn btn-outline-light btn-md d-md-inline-flex mr10"
                                                            onClick={debouncedFAQ}
                                                        >
                                                            <em className="icon ni ni-info"></em>
                                                            <span>FAQ</span>
                                                        </a>
                                                    </li>
                                                    <li>
                                                        <a
                                                            className="btn btn-primary d-none d-md-inline-flex"
                                                            onClick={debouncedBack}
                                                        >
                                                            <em className="icon ni ni-arrow-left"></em>
                                                            <span>Back</span>
                                                        </a>
                                                    </li>
                                                </>
                                            )}

                                        </>
                                    )}
                            </ul>
                        </div>
                    </div>
                </div>
                {/* ====================================================== Body section ====================================================== */}
                <div className="nk-block">
                    <ul className="nav nav-tabs mb-3 nav-tabs-s1 pos-sticky">
                        <li className="nav-item">    <a className="nav-link active" href="#summary">Summary</a>  </li>
                        <li className="nav-item">    <a className="nav-link" href="#pricing">Pricing</a>  </li>
                        <li className="nav-item">    <a className="nav-link" href="#discount">Discount</a>  </li>
                        <li className="nav-item">    <a className="nav-link" href="#trainers">Trainers</a>  </li>
                    </ul>
                    <div className="tab-content" id="myTabContent">
                        <div className="tab-pane fade show active" id="summary">
                            <div className="row">
                                <div className="col-md-4">
                                    <div className="row">
                                        <div className="col-md-12">
                                            <h5 className="summary">Summary</h5>
                                            <p className="ptitle">Enter essential course details, including course title, short title, tagline, and other information to user on website.</p>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-md-8">
                                    <div className="row">
                                        <div className="col-lg-12">
                                            <div className="form-group">

                                                <label htmlFor="tax-class" className="form-label">Upload Photos</label>
                                                {/* <div className="form-control-wrap">
                                                    <input type="file" id="fileInput" multiple accept="image/*" onChange={handleImageChange} />
                                                </div> */}

                                                <div className="form-control-wrap">
                                                    <input type="file" id="fileInput" multiple accept="image/*" onChange={handleImageChange} />
                                                    {/* Render selected images with preview and remove button */}
                                                    <div className="image-preview">
                                                        {oldimages.map((image, index) => (
                                                            <div key={index} className="preview-item">
                                                                <img src={image} alt={`Image ${index}`} className="preview-image" />
                                                                <button onClick={() => removeOldImage(index)}> <em className="icon ni ni-cross"></em></button>
                                                            </div>
                                                        ))}
                                                        {images.map((image, index) => (
                                                            <div key={index} className="preview-item">
                                                                <img src={image} alt={`Image ${index}`} className="preview-image" />
                                                                <button onClick={() => removeImage(index)}> <em className="icon ni ni-cross"></em></button>
                                                            </div>
                                                        ))}
                                                    </div>
                                                </div>
                                            </div>
                                        </div>




                                        <div className="col-lg-6">
                                            <div className="form-group">

                                                <label htmlFor="tax-class" className="form-label">Course type</label>
                                                <div className="form-control-wrap">
                                                    <Select2
                                                        data={[
                                                            { text: "select an option", id: 0 },
                                                            ...(tblcoursetypes && tblcoursetypes.data && tblcoursetypes.data.hasOwnProperty("coursetypes") && tblcoursetypes.data.coursetypes || []).map((x) => ({
                                                                text: x.course_type_name,
                                                                id: x.course_type_id,
                                                                key: x.course_type_id.toString(),
                                                            })),
                                                        ]}
                                                        options={{
                                                            placeholder: "select an option",
                                                        }}
                                                        name="course_type_id"
                                                        className="form-control"
                                                        value={state.fields.course_type_id}
                                                        onChange={handleInputChange}
                                                    />

                                                    {/* <select className="js-select select2_single" id="ddcoursetype" data-search="true" data-sort="false">
                                                        <option value="">Select an option</option>
                                                        <option value="1">Admin</option>
                                                        <option value="2">Sub admin</option>
                                                    </select> */}
                                                </div>
                                            </div>
                                        </div>

                                        <div className="col-md-6">
                                            <div className="form-group">
                                                <label className="form-label">Course title (max 200 characters)</label>
                                                <div className="form-control-wrap">
                                                    <textarea 
                                                        type="text"
                                                        placeholder="Enter course title"
                                                        name="course_name" 
                                                        maxLength={200}
                                                        className="form-control"
                                                        value={state.fields.course_name}
                                                        onChange={handleInputChange}
                                                        
                                                    />
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-md-6">
                                            <div className="form-group">
                                                <label className="form-label">Course short (max 80 characters)</label>
                                                <div className="form-control-wrap">
                                                    <textarea
                                                        type="text"
                                                        placeholder="Enter course short"
                                                        name="course_short"
                                                        maxLength={80}
                                                        className="form-control"
                                                        value={state.fields.course_short}
                                                        onChange={handleInputChange} 
                                                    />
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-md-6">
                                            <div className="form-group">
                                                <label className="form-label">Tagline (max 80 characters)</label>
                                                <div className="form-control-wrap">
                                                    <textarea
                                                        type="text"
                                                        placeholder="Enter tagline"
                                                        name="tagline"
                                                        maxLength={80}
                                                        className="form-control"
                                                        value={state.fields.tagline}
                                                        onChange={handleInputChange}
                                                    />
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-md-6">
                                            <div className="form-group">
                                                <label className="form-label">Description</label>
                                                <div className="form-control-wrap">
                                                    <textarea
                                                        type="text"
                                                        placeholder="Enter description"
                                                        name="description"
                                                        className="form-control"
                                                        value={state.fields.description}
                                                        onChange={handleInputChange}
                                                    />
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-md-6">
                                            <div className="form-group">
                                                <label className="form-label">Terms (For attendees slot selection screen)</label>
                                                <div className="form-control-wrap">
                                                    <textarea
                                                        type="text"
                                                        placeholder="Enter key points"
                                                        name="key_points"
                                                        className="form-control"
                                                        value={state.fields.key_points}
                                                        onChange={handleInputChange}
                                                    />
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-md-6">
                                            <div className="form-group">
                                                <label className="form-label">Benefit point (Not used anywhere as of now)</label>
                                                <div className="form-control-wrap">
                                                    <textarea
                                                        type="text"
                                                        placeholder="Enter benefits point"
                                                        name="benefits_point"
                                                        className="form-control"
                                                        value={state.fields.benefits_point}
                                                        onChange={handleInputChange}
                                                    />
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-md-6">
                                            <div className="form-group">
                                                <label className="form-label">Notes (Internal use only)</label>
                                                <div className="form-control-wrap">
                                                    <textarea
                                                        type="text"
                                                        placeholder="Enter notes"
                                                        name="notes"
                                                        className="form-control"
                                                        value={state.fields.notes}
                                                        onChange={handleInputChange}
                                                    />
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-md-6">
                                            <div className="form-group">
                                                <label className="form-label">Active status</label>
                                                <div className="form-control-wrap">
                                                    <div className="form-check form-switch">
                                                        <input
                                                            className="form-check-input"
                                                            type="checkbox"
                                                            checked={state.fields.is_active}
                                                            onChange={handleCheckboxActiveClick}
                                                            value=""
                                                        />
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>

                                </div>


                            </div>
                        </div>
                        <div className="tab-pane fade show active" id="pricing">
                            <span className="divider "></span>
                            <div className="row">
                                <div className="col-md-4">
                                    <div className="row">
                                        <div className="col-md-12">

                                            <h5 className="summary">Pricing or Fees</h5>
                                            <p className="ptitle">Toggle the "Free course" to grant students or professinals to register with this course without paying fees and by default its paid course.</p>

                                        </div>
                                    </div>
                                </div>
                                <div className="col-md-8">
                                    <div className="row">
                                        <div className="col-md-12">
                                            <div className="form-group">
                                                <label className="form-label">Free course</label>
                                                <div className="form-control-wrap">
                                                    <div className="form-check form-switch">
                                                        <input
                                                            className="form-check-input"
                                                            type="checkbox"
                                                            checked={state.fields.is_free}
                                                            onChange={() => handleCheckboxClick("is_free")}
                                                            value=""
                                                        />
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        {!state.fields.is_free && (
                                            <>
                                                <div className="col-md-6">
                                                    <div className="form-group">
                                                        <label className="form-label">Professional fee</label>
                                                        <div className="form-control-wrap">
                                                            <input
                                                                type="text"
                                                                placeholder="Enter professional fees"
                                                                name="p_amount"
                                                                className="form-control"
                                                                value={state.fields.p_amount}
                                                                onChange={handleInputChange}
                                                            />
                                                        </div>
                                                    </div>
                                                </div>

                                                <div className="col-md-6">
                                                    <div className="form-group">
                                                        <label className="form-label">Prof. fees 2 to 3</label>
                                                        <div className="form-control-wrap">
                                                            <input
                                                                type="text"
                                                                placeholder="Enter prof. fees 2 to 3"
                                                                name="g3_amount"
                                                                className="form-control"
                                                                value={state.fields.g3_amount}
                                                                onChange={handleInputChange}
                                                            />
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="col-md-6">
                                                    <div className="form-group">
                                                        <label className="form-label">Prof. fees 4 to 6</label>
                                                        <div className="form-control-wrap">
                                                            <input
                                                                type="text"
                                                                placeholder="Enter prof. fees 4 to 6"
                                                                name="g6_amount"
                                                                className="form-control"
                                                                value={state.fields.g6_amount}
                                                                onChange={handleInputChange}
                                                            />
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="col-md-6">
                                                    <div className="form-group">
                                                        <label className="form-label">Prof. fees 7 to 9</label>
                                                        <div className="form-control-wrap">
                                                            <input
                                                                type="text"
                                                                placeholder="Enter prof. fees 7 to 9"
                                                                name="g9_amount"
                                                                className="form-control"
                                                                value={state.fields.g9_amount}
                                                                onChange={handleInputChange}
                                                            />
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="col-md-6">
                                                    <div className="form-group">
                                                        <label className="form-label">Prof. fees more than 10</label>
                                                        <div className="form-control-wrap">
                                                            <input
                                                                type="text"
                                                                placeholder="Enter prof. fees more than 10"
                                                                name="g12_amount"
                                                                className="form-control"
                                                                value={state.fields.g12_amount}
                                                                onChange={handleInputChange}
                                                            />
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="col-md-6">
                                                    <div className="form-group">
                                                        <label className="form-label">Student fee</label>
                                                        <div className="form-control-wrap">
                                                            <input
                                                                type="text"
                                                                placeholder="Enter student fee"
                                                                name="s_amount"
                                                                className="form-control"
                                                                value={state.fields.s_amount}
                                                                onChange={handleInputChange}
                                                            />
                                                        </div>
                                                    </div>
                                                </div>

                                                <div className="col-md-6">
                                                    <div className="form-group">
                                                        <label className="form-label">Stu. fees 2 to 3</label>
                                                        <div className="form-control-wrap">
                                                            <input
                                                                type="text"
                                                                placeholder="Enter Stu. fees 2 to 3"
                                                                name="s3_amount"
                                                                className="form-control"
                                                                value={state.fields.s3_amount}
                                                                onChange={handleInputChange}
                                                            />
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="col-md-6">
                                                    <div className="form-group">
                                                        <label className="form-label">Stu. fees 4 to 6</label>
                                                        <div className="form-control-wrap">
                                                            <input
                                                                type="text"
                                                                placeholder="Enter Stu. fees 4 to 6"
                                                                name="s6_amount"
                                                                className="form-control"
                                                                value={state.fields.s6_amount}
                                                                onChange={handleInputChange}
                                                            />
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="col-md-6">
                                                    <div className="form-group">
                                                        <label className="form-label">Stu. fees 7 to 9</label>
                                                        <div className="form-control-wrap">
                                                            <input
                                                                type="text"
                                                                placeholder="Enter Stu. fees 7 to 9"
                                                                name="s9_amount"
                                                                className="form-control"
                                                                value={state.fields.s9_amount}
                                                                onChange={handleInputChange}
                                                            />
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="col-md-6">
                                                    <div className="form-group">
                                                        <label className="form-label">Stu. fees more than 10</label>
                                                        <div className="form-control-wrap">
                                                            <input
                                                                type="text"
                                                                placeholder="Enter Stu. fees more than 10"
                                                                name="s12_amount"
                                                                className="form-control"
                                                                value={state.fields.s12_amount}
                                                                onChange={handleInputChange}
                                                            />
                                                        </div>
                                                    </div>
                                                </div>


                                            </>
                                        )}
                                    </div>





                                </div>
                            </div>
                        </div>
                        <div className="tab-pane fade show active" id="discount">
                            <span className="divider "></span>
                            <div className="row">
                                <div className="col-md-4">
                                    <div className="row">
                                        <div className="col-md-12">
                                            <h5 className="summary">Discount Coupon</h5>
                                            <p className="ptitle">You can create coupon code for course and define how much discount you want to give wherever someone use this coupon in percentage by mentioning maximum time this coupon can be used.</p>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-md-8">
                                    <div className="row">
                                        <div className="col-md-6">
                                            <div className="form-group">
                                                <label className="form-label">Coupon code</label>
                                                <div className="form-control-wrap">
                                                    <input
                                                        type="text"
                                                        placeholder="Enter coupon code"
                                                        name="coupon_code"
                                                        maxLength={6}
                                                        className="form-control"
                                                        value={state.fields.coupon_code}
                                                        onChange={handleInputChange}
                                                    />
                                                </div>
                                            </div>
                                        </div>

                                        <div className="col-md-6">
                                            <div className="form-group">
                                                <label className="form-label">Maximum coupon users</label>
                                                <div className="form-control-wrap">
                                                    <input
                                                        type="text"
                                                        placeholder="Enter user count"
                                                        name="max_coupon"
                                                        className="form-control"
                                                        value={state.fields.max_coupon}
                                                        onChange={handleInputChange}
                                                    />
                                                </div>
                                            </div>
                                        </div>

                                        <div className="col-md-6">
                                            <div className="form-group">
                                                <label className="form-label">Discount percentage</label>
                                                <div className="form-control-wrap">
                                                    <input
                                                        type="text"
                                                        placeholder="Enter discount percentage"
                                                        name="d_amount"
                                                        className="form-control"
                                                        value={state.fields.d_amount}
                                                        onChange={handleInputChange}
                                                    />
                                                </div>
                                            </div>
                                        </div>



                                    </div>





                                </div>
                            </div>
                        </div>
                        <div className="tab-pane fade show active" id="trainers">
                            <span className="divider "></span>
                            <div className="row">
                                <div className="col-md-4">
                                    <div className="row">
                                        <div className="col-md-12">
                                            <h5 className="summary">Course trainers</h5>
                                            <p className="ptitle">Select trainers who are responsible to teach this course to all students and their profile will be visible on course detail screen on website.</p>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-md-8">
                                    <div className="row">
                                        <div className="col-lg-12">
                                            <div className="form-group">
                                                <label htmlFor="tax-class" className="form-label">Select trainers</label>
                                                <div className="form-control-wrap">
                                                    <Select2
                                                        multiple
                                                        data={[
                                                            { text: "select an option", id: 0 },
                                                            ...(tblusers && tblusers.data && tblusers.data.hasOwnProperty("users") && tblusers.data.users || []).map((x) => ({
                                                                text: x.first_name + ' ' + x.last_name,
                                                                id: x.user_id,
                                                                key: x.user_id.toString(),
                                                            })),
                                                        ]}
                                                        options={{
                                                            placeholder: "select an option",
                                                        }}
                                                        name="teacher_id"
                                                        className="form-control"
                                                        value={state.fields.teacher_id}
                                                        onChange={handleSelectMultipleChange}
                                                    />
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div >
                </div >
                {/* ====================================================== Footer Section ====================================================== */}
                < div className="divbottom" >
                    <div className="bottombutton ">
                        <ul className="d-flex align-items-center gap g-3">
                            <li>
                                <a
                                    className="btn  btn-outline-light"
                                    onClick={debouncedBack}
                                >
                                    <em className="icon ni ni-arrow-left"></em>
                                    <span>Cancel</span>
                                </a>
                            </li>
                            {tblhasrights &&
                                tblhasrights.data &&
                                Array.isArray(tblhasrights.data.hasrights) &&
                                tblhasrights.data.hasrights.length > 0 &&
                                tblhasrights.data.hasrights[0].hasOwnProperty("write_status") &&
                                tblhasrights.data.hasrights[0]["write_status"] && (
                                    <>
                                        <li> <button
                                            type="button"
                                            className="btn btn-primary"
                                            name="btnsave"
                                            id="btnsave"
                                            onClick={debouncedSaveClicked}
                                        >
                                            <span>{state.is_edit ? 'Update record' : 'Create record'}</span>
                                        </button> </li>
                                    </>
                                )}


                        </ul>
                    </div>
                </div >
                {/* ====================================================== Toast message ====================================================== */}
                {
                    state.show_toast && (
                        <div
                            className="toast align-items-center text-bg-primary border-0 fade show toastmsg"
                            role="alert"
                            aria-live="assertive"
                            aria-atomic="true"
                        >
                            <div className="d-flex">
                                <div className="toast-body">{state.toast_msg}</div>
                                <button
                                    type="button"
                                    className="btn-close btn-close-white me-2 m-auto"
                                    data-bs-dismiss="toast"
                                    onClick={(e) => hideToast()}
                                    aria-label="Close"
                                ></button>
                            </div>
                        </div>
                    )
                }
            </>
        </>
    );
};
export default adminLayout(CourseEntry);