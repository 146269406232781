import React, { useState, useEffect, useCallback } from "react";
import { Link } from 'react-router-dom';
import authLayout from "../hoc/authLayout";
import logoimage from "../assets/images/friendsup.png";
import Select2 from "react-select2-wrapper";
import faviconimg from "../images/favicon.png";
import axios from "axios";
import baseURL from "../common/base-urls.js";
import "react-select2-wrapper/css/select2.css";
import debounce from "lodash/debounce";
import useSWR, { mutate } from "swr";
import { useNavigate, useLocation } from "react-router-dom";
import { update } from "lodash";

const ForgotPassword = () => {
  const navigate = useNavigate();
  const pageData = useLocation();
  const [apiUrl, setApiUrl] = useState('');
  const [loading, setLoading] = useState(false); // Add loading state
  useEffect(() => {
    const currentTime = new Date();
    const formattedTime = currentTime.toLocaleTimeString([], { hour: '2-digit', minute: '2-digit' });
    //console.log(`=======================================`);
    //console.log(`========= Updated on ${formattedTime} =========`);
    //console.log(`=======================================`);
    //console.log(pageData);
    return () => {
    };
  }, [pageData]);
  //#region ====================================================== Create State =====================================================
  const [state, setState] = useState({
    show_toast: false,
    toast_msg: "No record found",
    isregister: false,
    step: "0",
    fields: {
      phone: "",
      otp: "",
      password: "",
      confirmpassword: ""
    },
    apifields: {
      phone: "",
      otp: "",
      password: "",
      confirmpassword: ""
    },
    oldfields: {
      phone: "",
      otp: "",
      password: "",
      confirmpassword: ""
    },
  });
  //#region ====================================================== Input Textbox ======================================================
  const handleInputChange = (event) => {
    const { name, value } = event.target;
    setState((prevState) => ({
      ...prevState,
      fields: {
        ...prevState.fields,
        [name]: value,
      },
    }));
  };
  //#region ====================================================== Update State ======================================================
  const updateState = (key, value) => {
    setState((prevState) => ({
      ...prevState,
      [key]: value,
    }));
  };
  const clearData = async () => {
    try {
      const updatedapifieldsState = {
        phone: "",
        otp: "",
        password: "",
        confirmpassword: ""
      };
      const updatedfieldState = {
        phone: "",
        otp: "",
        password: "",
        confirmpassword: ""
      };
      updateState("oldfields", state.apifields);
      updateState("apifields", updatedapifieldsState);
      updateState("fields", updatedfieldState);
    } catch (err) { }
  };
  //#region ====================================================== Toast Message set ======================================================
  const showToast = (message) => {
    updateState("show_toast", true);
    updateState("toast_msg", message);
    setTimeout(() => {
      updateState("show_toast", false);
    }, 5000);
  };
  const hideToast = () => {
    updateState("show_toast", false);
  };
  //#endregion

  const areObjectsEqual = (obj1, obj2) => {
    const keys1 = Object.keys(obj1);
    const keys2 = Object.keys(obj2);

    if (keys1.length !== keys2.length) {
      return false;
    }

    for (const key of keys1) {
      const val1 = obj1[key];
      const val2 = obj2[key];

      if (typeof val1 === 'object' && typeof val2 === 'object') {
        if (!areObjectsEqual(val1, val2)) {
          return false;
        }
      } else if (val1 !== val2) {
        return false;
      }
    }

    return true;
  };
  useEffect(() => {
    const saveData = async () => {
      if (state.step == "1") {
        setLoading(true);
        try {
          const newapiFields = { ...state.apifields };
          const dataforregister = {
            "phone": newapiFields.phone,
            "device_id": "dummydeviceid"
          };
          const result = await postAPI('user/forgotPassword', dataforregister, false);
          //console.log(result)


          if (result && result.statusCode === 201 && result.success === true) {
            setState((prevState) => ({
              ...prevState,
              step: "2"
            }));
          } else {
            showToast("No account found with this phone number");
          }


          // if (result && result.statusCode === 201 && result.success === true) {
          //   const userData = result.data;
          //   localStorage.setItem('token', userData.token);
          //   localStorage.setItem('user_id', userData.user_id);
          //   localStorage.setItem('institute_id', userData.institute_id);
          //   localStorage.setItem('user', JSON.stringify(userData));
          //   clearData();
          //   navigate("/bookslot");
          // } else {
          //   showToast("Incorrect credentials");
          // }

        } catch (err) {
          showToast(err);
          setLoading(false);
        }
        setLoading(false);
      }
      else if (state.step == "3") {
        setLoading(true);
        try {
          const newapiFields = { ...state.apifields };
          const dataforregister = {
            "phone": newapiFields.phone,
            "otp": newapiFields.otp,
            "device_id": "dummydeviceid"
          };
          const filteredParams = Object.fromEntries(
            Object.entries(dataforregister).filter(([_, value]) => value != null && value !== '')
          );
          // Convert the filtered parameters into a query string
          const queryString = new URLSearchParams(filteredParams).toString();
          // Construct the final apiUrl
          const newApiUrl = `user/forgotPassword?${queryString}`;

          const response = await axios.get(`${baseURL.serverUrl}${newApiUrl}`, {
          });
          //console.log("cihsfjnbsfjnbsfjbfsjb");
          //console.log(response);
          const result = response.data;
          //console.log(result)
          if (result && result.statusCode === 200 && result.success === true) {
            if (result.data != null) {
              setState((prevState) => ({
                ...prevState,
                step: "4"
              }));
            }
            else {
              setState((prevState) => ({
                ...prevState,
                step: "2",
                fields: {
                  ...prevState.fields,
                  otp: ""
                },

              }));
              showToast("Wrong OTP. Please try again.");
            }
          } else {
            showToast("Wrong OTP. Please try again.");
          }


          // if (result && result.statusCode === 201 && result.success === true) {
          //   const userData = result.data;
          //   localStorage.setItem('token', userData.token);
          //   localStorage.setItem('user_id', userData.user_id);
          //   localStorage.setItem('institute_id', userData.institute_id);
          //   localStorage.setItem('user', JSON.stringify(userData));
          //   clearData();
          //   navigate("/bookslot");
          // } else {
          //   showToast("Incorrect credentials");
          // }

        } catch (err) {
          showToast(err);
          setLoading(false);
        }
        setLoading(false);
      }
      else if (state.step == "5") {
        setLoading(true);
        try {
          const newapiFields = { ...state.apifields };
          const dataforregister = {
            "phone": newapiFields.phone,
            "otp": newapiFields.otp,
            "password": newapiFields.password,
            "device_id": "dummydeviceid"
          };
          const result = await putAPI('user/forgotPassword', dataforregister, false);
         
          if (result && result.statusCode === 200 && result.success === true) {
            showToast("Password recovered successfully");
            setState((prevState) => ({
              ...prevState,
              step: "2"
            }));
            navigate("/");
            //redirect to login screen
          } else {
            setState((prevState) => ({
              ...prevState,
              step: "0"
            }));
            showToast("Password not set. Please try again with another password");
            //redirect to phone number screen
          }


          // if (result && result.statusCode === 201 && result.success === true) {
          //   const userData = result.data;
          //   localStorage.setItem('token', userData.token);
          //   localStorage.setItem('user_id', userData.user_id);
          //   localStorage.setItem('institute_id', userData.institute_id);
          //   localStorage.setItem('user', JSON.stringify(userData));
          //   clearData();
          //   navigate("/bookslot");
          // } else {
          //   showToast("Incorrect credentials");
          // }

        } catch (err) {
          showToast(err);
          setLoading(false);
        }
        setLoading(false);
      }
    };
    saveData();
    updateState("isregister", false);
  }, [state.step]);
  const saveClicked = () => {
    // if (state.fields.phone.length == 10 && state.fields.first_name != "" && state.fields.qualification != "" && state.fields.last_name != "" && state.fields.title != "" && state.fields.role_id != "" && state.fields.password.length > 5 && state.fields.password == state.fields.confirmpassword) {
    //   if (state.fields.role_id == "6" && state.fields.organisation == "") {
    //     showToast("Organisation can not be empty");
    //   }
    //   else {
    if (state.fields.phone.length == 10) {
      setState((prevState) => ({
        ...prevState,
        step: "1",
        apifields: {
          ...prevState.apifields,
          phone: prevState.fields.phone
        },
      }));

    }
    else {
      if (state.fields.phone.length != 10) {
        showToast("Enter 10 digit phone number");
      }
      // else if (state.fields.first_name == "") {
      //   showToast("First name cannot be blank");
      // }
      // else if (state.fields.last_name == "") {
      //   showToast("Last name cannot be blank");
      // }
      // else if (state.fields.qualification == "") {
      //   showToast("Qualification cannot be blank");
      // }
      // else if (state.fields.title == "") {
      //   showToast("Title name cannot be blank");
      // }
      // else if (state.fields.role_id == "") {
      //   showToast("Please select registration type");
      // }
      // else if (state.fields.password.length <= 5) {
      //   showToast("Password must be greater than 5 characters");
      // }
      // else if (state.fields.password == state.fields.confirmpassword) {
      //   showToast("Password doesn't match. please type again");
      //   setState((prevState) => ({
      //     ...prevState,
      //     fields: {
      //       ...prevState.fields,
      //       password: "",
      //       confirmpassword: "",
      //     },
      //   }));
      // }
    }
    window.scrollTo({ top: 0, behavior: 'smooth' });
  };
  const debouncedSaveClicked = debounce(saveClicked, 500);

  const verifyClicked = () => {
    // if (state.fields.phone.length == 10 && state.fields.first_name != "" && state.fields.qualification != "" && state.fields.last_name != "" && state.fields.title != "" && state.fields.role_id != "" && state.fields.password.length > 5 && state.fields.password == state.fields.confirmpassword) {
    //   if (state.fields.role_id == "6" && state.fields.organisation == "") {
    //     showToast("Organisation can not be empty");
    //   }
    //   else {
    if (state.fields.otp.length == 5) {
      setState((prevState) => ({
        ...prevState,
        step: "3",
        apifields: {
          ...prevState.apifields,
          otp: prevState.fields.otp
        },
      }));
    }
    else {
      if (state.fields.otp.length != 5) {
        showToast("Enter 5 digit OTP");
      }
      // else if (state.fields.first_name == "") {
      //   showToast("First name cannot be blank");
      // }
      // else if (state.fields.last_name == "") {
      //   showToast("Last name cannot be blank");
      // }
      // else if (state.fields.qualification == "") {
      //   showToast("Qualification cannot be blank");
      // }
      // else if (state.fields.title == "") {
      //   showToast("Title name cannot be blank");
      // }
      // else if (state.fields.role_id == "") {
      //   showToast("Please select registration type");
      // }
      // else if (state.fields.password.length <= 5) {
      //   showToast("Password must be greater than 5 characters");
      // }
      // else if (state.fields.password == state.fields.confirmpassword) {
      //   showToast("Password doesn't match. please type again");
      //   setState((prevState) => ({
      //     ...prevState,
      //     fields: {
      //       ...prevState.fields,
      //       password: "",
      //       confirmpassword: "",
      //     },
      //   }));
      // }
    }
    window.scrollTo({ top: 0, behavior: 'smooth' });
  };
  const debouncedVerify = debounce(verifyClicked, 500);

  const updateClicked = () => {
    // if (state.fields.phone.length == 10 && state.fields.first_name != "" && state.fields.qualification != "" && state.fields.last_name != "" && state.fields.title != "" && state.fields.role_id != "" && state.fields.password.length > 5 && state.fields.password == state.fields.confirmpassword) {
    //   if (state.fields.role_id == "6" && state.fields.organisation == "") {
    //     showToast("Organisation can not be empty");
    //   }
    //   else {
    if (state.fields.password.length > 5 && state.fields.password == state.fields.confirmpassword) {
      setState((prevState) => ({
        ...prevState,
        step: "5",
        apifields: {
          ...prevState.apifields,
          password: prevState.fields.password,
          confirmpassword: prevState.fields.confirmpassword
        },
      }));
    }
    else {

      showToast("Password and confirm password do not match.");

      // else if (state.fields.first_name == "") {
      //   showToast("First name cannot be blank");
      // }
      // else if (state.fields.last_name == "") {
      //   showToast("Last name cannot be blank");
      // }
      // else if (state.fields.qualification == "") {
      //   showToast("Qualification cannot be blank");
      // }
      // else if (state.fields.title == "") {
      //   showToast("Title name cannot be blank");
      // }
      // else if (state.fields.role_id == "") {
      //   showToast("Please select registration type");
      // }
      // else if (state.fields.password.length <= 5) {
      //   showToast("Password must be greater than 5 characters");
      // }
      // else if (state.fields.password == state.fields.confirmpassword) {
      //   showToast("Password doesn't match. please type again");
      //   setState((prevState) => ({
      //     ...prevState,
      //     fields: {
      //       ...prevState.fields,
      //       password: "",
      //       confirmpassword: "",
      //     },
      //   }));
      // }
    }
    window.scrollTo({ top: 0, behavior: 'smooth' });
  };
  const debouncedUpdate = debounce(updateClicked, 500);


  const handleInputChangeNumber = (event) => {
    const { name, value } = event.target;
    // Remove non-digit characters from the input value
    const newValue = value.replace(/\D/g, '');
    setState((prevState) => ({
      ...prevState,
      fields: {
        ...prevState.fields,
        [name]: newValue,
      },
    }));
  };


  //#region ====================================================== Get API ======================================================
  const getAPI = async (url, Auth) => {
    try {
      let getUser = localStorage.getItem("user");
      if (getUser) {
        getUser = JSON.parse(getUser);
        if (Auth) {
          url += `&user_id=${getUser.user_id}`;
        }
        const response = await axios.get(`${baseURL.serverUrl}${url}`, {
          headers: {
            "x-access-token": getUser.token,
            Authorization: `Bearer ${getUser.token}`,
          },
        });
        return response.data;
      } else {
        return {};
      }
    } catch (err) {
      return {};
    }
  };
  //#endregion
  //#region ====================================================== Post API ======================================================
  const putAPI = async (url, data, Auth) => {
    try {
      const response = await axios.put(`${baseURL.serverUrl}${url}`, data, {
        headers: {
          "x-access-token": '',
          'Content-Type': 'application/json',
        },
      });
      return response.data;
    } catch (err) {
      return {};
    }
  };
  //#endregion 
  //#region ====================================================== Post API ======================================================
  const postAPI = async (url, data, Auth) => {
    try {
      const response = await axios.post(`${baseURL.serverUrl}${url}`, data, {
        headers: {
          "x-access-token": '',
          'Content-Type': 'application/json',
        },
      });
      return response.data;
    } catch (err) {
      return {};
    }
  };
  //#endregion  
  return (
    <>
      <div className="flex min-h-screen w-full flex-col justify-between">
        <header className="flex items-center justify-between p-4 lg:px-16 lg:py-6 2xl:px-24">
          <a >
            <img alt="FriendsupAdmin" width="155" height="28" decoding="async" data-nimg="1" className="dark:invert logoic" src={logoimage} />
          </a>
          <div className="flex items-center space-x-2 md:space-x-4">
            <a className="inline-flex items-center gap-x-1 rounded-3xl p-2 py-1 text-sm font-medium transition-colors hover:bg-gray-200 md:px-4 md:py-2.5 [&amp;&gt;svg]:w-4 bg-gray-100 text-gray-900 [&amp;&gt;svg]:text-gray-900" >
              <svg stroke="currentColor" fill="currentColor" strokeWidth="0" viewBox="0 0 256 256" className="h-4 w-4" height="1em" width="1em" xmlns="http://www.w3.org/2000/svg">
                <path d="M189.66,122.34a8,8,0,0,1,0,11.32l-72,72a8,8,0,0,1-11.32-11.32L164.69,136H32a8,8,0,0,1,0-16H164.69L106.34,61.66a8,8,0,0,1,11.32-11.32ZM216,32a8,8,0,0,0-8,8V216a8,8,0,0,0,16,0V40A8,8,0,0,0,216,32Z"></path>
              </svg>
              <span>Reset password</span>
            </a>
          </div>
        </header>
        <div className="flex w-full flex-col justify-center px-5">
          <div className="mx-auto w-full max-w-md md:max-w-lg lg:max-w-xl 2xl:pb-8 ">
            <div className="flex flex-col items-center">
              <h2 className="rizzui-title-h2 mb-7 text-center text-[28px] font-bold leading-snug md:text-3xl md:!leading-normal lg:mb-10 lg:text-4xl logtitle">Reset Password<br /></h2>
            </div>
            <form noValidate="">
              <div className="space-y-5 lg:space-y-6">
                {state.step == "0" && (
                  <>
                    <div className="form-group">
                      <label htmlFor="productname" className="form-label">Phone</label>
                      <div className="form-control-wrap">
                        <input type="text" name="phone" maxLength={10} className="form-control py-2 h-14  x-5" id="txtphone" placeholder="Enter phone number to find your account"
                          value={state.fields.phone}
                          onChange={handleInputChangeNumber} />
                      </div>
                    </div>
                    <p className="mt-6 text-center text-[15px] leading-loose text-gray-500 md:mt-7 lg:mt-9 lg:text-sm">
                      Please provide the correct phone number to receive the OTP for verification purposes.
                    </p>
                    <button type="button" className="rizzui-button inline-flex font-medium items-center justify-center active:enabled:translate-y-px focus:outline-none focus-visible:ring-2 focus-visible:ring-opacity-50 transition-colors duration-200 px-8 py-2.5 text-base h-14 rounded-md border border-transparent focus-visible:ring-offset-2 bg-gray-900 hover:enabled::bg-gray-800 active:enabled:bg-gray-1000 focus-visible:ring-gray-900/30 text-gray-0 w-full"
                      id="btncontinue" onClick={debouncedSaveClicked}> {loading ? 'Looking For Account...' : 'Continue'}</button>
                    <p className="mt-6 text-center text-[15px] leading-loose text-gray-500 md:mt-7 lg:mt-9 lg:text-base">
                      Remember password? <a className="font-semibold text-gray-700 transition-colors hover:text-primary" href={"/"} >Login now</a>
                    </p>   </>
                )}
                {state.step == "2" && (
                  <>
                    <div className="form-group">
                      <label htmlFor="productname" className="form-label">Enter OTP sent to your phone number</label>
                      <div className="form-control-wrap">
                        <input type="text" name="otp" maxLength={5} className="form-control py-2 h-14  x-5" id="txtotp" placeholder="5 Digit OTP"
                          value={state.fields.otp}
                          onChange={handleInputChangeNumber} />
                      </div>
                    </div>
                    <button type="button" className="rizzui-button inline-flex font-medium items-center justify-center active:enabled:translate-y-px focus:outline-none focus-visible:ring-2 focus-visible:ring-opacity-50 transition-colors duration-200 px-8 py-2.5 text-base h-14 rounded-md border border-transparent focus-visible:ring-offset-2 bg-gray-900 hover:enabled::bg-gray-800 active:enabled:bg-gray-1000 focus-visible:ring-gray-900/30 text-gray-0 w-full"
                      id="btnverify" onClick={debouncedVerify}> {loading ? 'Verifying OTP...' : 'Verify'}</button>
                    <p className="mt-6 text-center text-[15px] leading-loose text-gray-500 md:mt-7 lg:mt-9 lg:text-base">
                      The OTP has not been received yet. You may <a className="font-semibold text-gray-700 transition-colors hover
" href={"/#/forgotpassword"} >go back and try again</a>.
                    </p>
                  </>
                )}
                {state.step == "4" && (
                  <>
                    <div className="form-group">
                      <label htmlFor="productname" className="form-label">Password</label>
                      <div className="form-control-wrap">
                        <input name="password" type="password" className="form-control  py-2 h-14  x-5" id="txtpassword" placeholder="Enter password" value={state.fields.password}
                          onChange={handleInputChange} />
                      </div>
                    </div>
                    <div className="form-group">
                      <label htmlFor="productname" className="form-label">Confirm Password</label>
                      <div className="form-control-wrap">
                        <input name="confirmpassword" type="password" className="form-control  py-2 h-14  x-5" id="txtconfirmpassword" placeholder="Enter password again" value={state.fields.confirmpassword}
                          onChange={handleInputChange} />
                      </div>
                    </div>
                    <p className="mt-6 text-center text-[15px] leading-loose text-gray-500 md:mt-7 lg:mt-9 lg:text-sm">
                      Password should be more than 5 characters.
                    </p>
                    <button type="button" className="rizzui-button inline-flex font-medium items-center justify-center active:enabled:translate-y-px focus:outline-none focus-visible:ring-2 focus-visible:ring-opacity-50 transition-colors duration-200 px-8 py-2.5 text-base h-14 rounded-md border border-transparent focus-visible:ring-offset-2 bg-gray-900 hover:enabled::bg-gray-800 active:enabled:bg-gray-1000 focus-visible:ring-gray-900/30 text-gray-0 w-full"
                      id="btnupdate" onClick={debouncedUpdate}> {loading ? 'Updating password...' : 'Update password'}</button>

                  </>
                )}
              </div>
            </form>

          </div>
        </div>
        <footer className="footerdisplay flex flex-col-reverse items-center justify-between px-4 py-5 lg:flex-row lg:px-16 lg:py-6 2xl:px-24 2xl:py-10 .footerpadding">
          <div className="text-center leading-relaxed text-gray-500 lg:text-start">
            © Copyright 2024.  <a className="font-medium transition-colors hover:text-primary" >FriendsupAdmin</a>, all rights reserved.
          </div>
          <div className="-mx-2.5 flex items-center justify-end pb-3 font-medium footercenter text-gray-700 lg:w-1/2 lg:pb-0"><a className="px-2.5 py-1.5 transition-colors hover:text-primary" href="https://FriendsupAdmintsdc.in/">Visit Website</a><a className="px-2.5 py-1.5 transition-colors hover:text-primary" href="https://FriendsupAdmintsdc.in/#/privacy" >Privacy</a><a className="px-2.5 py-1.5 transition-colors hover:text-primary" href="https://FriendsupAdmintsdc.in/#/terms">Terms</a></div>
        </footer>
      </div>
      {/* ====================================================== Toast message ====================================================== */}
      {state.show_toast && (
        <div
          className="toast align-items-center text-bg-primary border-0 fade show toastmsg"
          role="alert"
          aria-live="assertive"
          aria-atomic="true"
        >
          <div className="d-flex">
            <div className="toast-body">{state.toast_msg}</div>
            <button
              type="button"
              className="btn-close btn-close-white me-2 m-auto"
              data-bs-dismiss="toast"
              onClick={(e) => hideToast()}
              aria-label="Close"
            ></button>
          </div>
        </div>
      )}


    </>
  );
};

export default authLayout(ForgotPassword);
