import React, { useState, useEffect, useCallback } from "react";
import { Link } from 'react-router-dom';
import authLayout from "../hoc/authLayout";
import logoimage from "../assets/images/friendsup.png";
import Select2 from "react-select2-wrapper";
import faviconimg from "../images/favicon.png";
import axios from "axios";
import baseURL from "../common/base-urls.js";
import "react-select2-wrapper/css/select2.css";
import debounce from "lodash/debounce";
import useSWR, { mutate } from "swr";
import { useNavigate, useLocation } from "react-router-dom";

const Register = () => {
  const navigate = useNavigate();
  const pageData = useLocation();
  const [apiUrl, setApiUrl] = useState('');
  const [loading, setLoading] = useState(false); // Add loading state
  useEffect(() => {
    const currentTime = new Date();
    const formattedTime = currentTime.toLocaleTimeString([], { hour: '2-digit', minute: '2-digit' });
    //console.log(`=======================================`);
    //console.log(`========= Updated on ${formattedTime} =========`);
    //console.log(`=======================================`);
    //console.log(pageData);
    return () => {
    };
  }, [pageData]);
  //#region ====================================================== Create State =====================================================
  const [state, setState] = useState({
    show_toast: false,
    toast_msg: "No record found",
    isregister: false,
    fields: {
      phone: "",
      first_name: "",
      last_name: "",
      title: "",
      role_id: "",
      organisation: "",
      qualification: "",
      email: "",
      password: ""
    },
    apifields: {
      phone: "",
      first_name: "",
      last_name: "",
      organisation: "",
      qualification: "",
      title: "",
      role_id: "",
      email: "",
      password: ""
    },
    oldfields: {
      phone: "",
      first_name: "",
      last_name: "",
      organisation: "",
      qualification: "",
      title: "",
      role_id: "",
      email: "",
      password: ""
    },
  });
  //#region ====================================================== Input Textbox ======================================================
  const handleInputChange = (event) => {
    const { name, value } = event.target;
    setState((prevState) => ({
      ...prevState,
      fields: {
        ...prevState.fields,
        [name]: value,
      },
    }));
  };
  //#region ====================================================== Update State ======================================================
  const updateState = (key, value) => {
    setState((prevState) => ({
      ...prevState,
      [key]: value,
    }));
  };
  const clearData = async () => {
    try {
      const updatedapifieldsState = {
        phone: "",
        first_name: "",
        last_name: "",
        organisation: "",
        qualification: "",
        title: "",
        role_id: "",
        email: "",
        password: "",
        confirmpassword: ""
      };
      const updatedfieldState = {
        phone: "",
        first_name: "",
        organisation: "",
        qualification: "",
        last_name: "",
        title: "",
        role_id: "",
        email: "",
        password: "",
        confirmpassword: ""
      };
      updateState("oldfields", state.apifields);
      updateState("apifields", updatedapifieldsState);
      updateState("fields", updatedfieldState);
    } catch (err) { }
  };
  //#region ====================================================== Toast Message set ======================================================
  const showToast = (message) => {
    updateState("show_toast", true);
    updateState("toast_msg", message);
    setTimeout(() => {
      updateState("show_toast", false);
    }, 5000);
  };
  const hideToast = () => {
    updateState("show_toast", false);
  };
  //#endregion
  const handleInputChangeNumber = (event) => {
    const { name, value } = event.target;
    // Remove non-digit characters from the input value
    const newValue = value.replace(/\D/g, '');
    setState((prevState) => ({
      ...prevState,
      fields: {
        ...prevState.fields,
        [name]: newValue,
      },
    }));
  };
  const areObjectsEqual = (obj1, obj2) => {
    const keys1 = Object.keys(obj1);
    const keys2 = Object.keys(obj2);

    if (keys1.length !== keys2.length) {
      return false;
    }

    for (const key of keys1) {
      const val1 = obj1[key];
      const val2 = obj2[key];

      if (typeof val1 === 'object' && typeof val2 === 'object') {
        if (!areObjectsEqual(val1, val2)) {
          return false;
        }
      } else if (val1 !== val2) {
        return false;
      }
    }

    return true;
  };
  useEffect(() => {
    const saveData = async () => {
      if (state.isregister === true) {
        setLoading(true);
        try {
          if (!areObjectsEqual(state.oldfields, state.apifields)) {
            const newapiFields = { ...state.apifields };
            const dataforregister = {
              "first_name": newapiFields.first_name,
              "last_name": newapiFields.last_name,
              "title": newapiFields.title,
              "phone": newapiFields.phone,
              "organisation": newapiFields.organisation,
              "qualification": newapiFields.qualification,
              "email": newapiFields.email,
              "role_id": newapiFields.role_id,
              "role_id": 2,
              "is_login": true,
              "apptype": "ts",
              "password": newapiFields.password,
              "fcm_token": "dummyfcmtoken",
              "device_id": "dummydeviceid"
            };
            const result = await postAPI('user/register', dataforregister, false);
            //console.log(result)
            if (result && result.statusCode === 201 && result.success === true) {
              const userData = result.data;
              localStorage.setItem('token', userData.token);
              localStorage.setItem('user_id', userData.user_id);
              localStorage.setItem('role_id', userData.role_id);
              localStorage.setItem('institute_id', userData.schoolid);
              localStorage.setItem('profile_small', userData.profile_small);
              localStorage.setItem('user_code', userData.user_code);
              localStorage.setItem('badge_color', userData.badge.badge_color); 
              localStorage.setItem('username', userData.title + " " + userData.first_name + " " + userData.last_name);
              localStorage.setItem('user', JSON.stringify(userData));
        
              // localStorage.setItem('token', userData.token);
              // localStorage.setItem('user_id', userData.user_id);
              // localStorage.setItem('institute_id', userData.institute_id);
              // localStorage.setItem('user', JSON.stringify(userData));
              clearData();
              navigate("/bookslot");
            } else {
              showToast("Account already exists. Try to login with phone number");
            }
          } else {
            showToast("Data has not changed. Please add unique records.");
          }
        } catch (err) {
          showToast(err);
          setLoading(false);
        }
        setLoading(false);
      }
      else {
        // const token = localStorage.getItem('token');
        // const user_id = localStorage.getItem('user_id');
        // const institute_id = localStorage.getItem('institute_id');
        // if (token && user_id && institute_id) {
        //   navigate("/bookslot");
        // }
      }
    };
    saveData();
    updateState("isregister", false);
  }, [state.isregister]);
  const saveClicked = () => {
    if (state.fields.phone.length == 10 && state.fields.organisation != "" && state.fields.first_name != "" && state.fields.qualification != "" && state.fields.last_name != "" && state.fields.title != "" && state.fields.role_id != "" && state.fields.password.length > 5 && state.fields.password == state.fields.confirmpassword) {

      console.log("inside saved");
      setState((prevState) => ({
        ...prevState,
        isregister: true,
        apifields: {
          ...prevState.apifields,
          phone: prevState.fields.phone,
          first_name: prevState.fields.first_name,
          last_name: prevState.fields.last_name,
          organisation: prevState.fields.organisation,
          qualification: prevState.fields.qualification,
          title: prevState.fields.title,
          role_id: prevState.fields.role_id,
          email: prevState.fields.email,
          password: prevState.fields.password
        },
      }));


    }
    else {
      
      console.log("inside saved 1");
      if (state.fields.phone.length != 10) {
        showToast("Enter 10 digit phone number");
      }
      else if (state.fields.first_name == "") {
        showToast("First name cannot be blank");
      }
      else if (state.fields.last_name == "") {
        showToast("Last name cannot be blank");
      }
      else if (state.fields.qualification == "") {
        showToast("Qualification cannot be blank");
      }
      else if (state.fields.title == "") {
        showToast("Title name cannot be blank");
      }
      else if (state.fields.organisation == "") {
        showToast("Organisation/Institute name cannot be blank");
      }
      else if (state.fields.role_id == "") {
        showToast("Please select registration type");
      }
      else if (state.fields.password.length <= 5) {
        showToast("Password must be greater than 5 characters");
      }
      else if (state.fields.password != state.fields.confirmpassword) {
        showToast("Password doesn't match. please type again");
        setState((prevState) => ({
          ...prevState,
          fields: {
            ...prevState.fields,
            password: "",
            confirmpassword: "",
          },
        }));
      }
    }
    window.scrollTo({ top: 0, behavior: 'smooth' });
  };
  const debouncedSaveClicked = debounce(saveClicked, 500);
  //#region ====================================================== Post API ======================================================
  const postAPI = async (url, data, Auth) => {
    try {
      const response = await axios.post(`${baseURL.serverUrl}${url}`, data, {
        headers: {
          "x-access-token": '',
          'Content-Type': 'application/json',
        },
      });
      return response.data;
    } catch (err) {
      return {};
    }
  };
  //#endregion 


  return (
    <>
      <div className="flex min-h-screen w-full flex-col justify-between">
        <header className="flex items-center justify-between p-4 lg:px-16 lg:py-6 2xl:px-24">
          <a href="https://FriendsupAdmintsdc.in/">
            <img alt="FriendsupAdmin" width="155" height="28" decoding="async" data-nimg="1" className="dark:invert logoic" src={logoimage} />
          </a>
          <div className="flex items-center space-x-2 md:space-x-4">
            <a className="inline-flex items-center gap-x-1 rounded-3xl p-2 py-1 text-sm font-medium transition-colors hover:bg-gray-200 md:px-4 md:py-2.5 [&amp;&gt;svg]:w-4 bg-gray-100 text-gray-900 [&amp;&gt;svg]:text-gray-900" >
              <svg stroke="currentColor" fill="currentColor" strokeWidth="0" viewBox="0 0 256 256" className="h-4 w-4" height="1em" width="1em" xmlns="http://www.w3.org/2000/svg">
                <path d="M189.66,122.34a8,8,0,0,1,0,11.32l-72,72a8,8,0,0,1-11.32-11.32L164.69,136H32a8,8,0,0,1,0-16H164.69L106.34,61.66a8,8,0,0,1,11.32-11.32ZM216,32a8,8,0,0,0-8,8V216a8,8,0,0,0,16,0V40A8,8,0,0,0,216,32Z"></path>
              </svg>
              <span>register</span>
            </a>
          </div>
        </header>
        <div className="flex w-full flex-col justify-center px-5">
          <div className="mx-auto w-full max-w-md md:max-w-lg lg:max-w-xl 2xl:pb-8 ">
            <div className="flex flex-col items-center">
              <h2 className="rizzui-title-h2 mb-7 text-center text-[28px] font-bold leading-snug md:text-3xl md:!leading-normal lg:mb-10 lg:text-4xl logtitle">Provide information<br /></h2>
            </div>
            <form noValidate="">
              <div className="space-y-5 lg:space-y-6">
                <div className="form-group">
                  <label htmlFor="tax-class" className="form-label">I am</label>
                  <div className="form-control-wrap">
                    <Select2
                      data={[
                        { text: "select an option", id: 0 },
                        { text: "Student", id: "5" },
                        { text: "Professional", id: "6" },
                      ]}
                      options={{
                        placeholder: "select an option",
                      }}
                      name="role_id"
                      className="form-control"
                      value={state.fields.role_id}
                      onChange={handleInputChange}
                    />
                  </div>
                </div>

                <div className="form-group">
                  <label htmlFor="tax-class" className="form-label">Title</label>
                  <div className="form-control-wrap">
                    <Select2
                      data={[
                        { text: "select an option", id: 0 },
                        { text: "Mr.", id: "Mr." },
                        { text: "Ms.", id: "Ms." },
                        { text: "Mrs.", id: "Mrs." },
                        { text: "Dr.", id: "Dr." },
                        { text: "Other", id: "Other" },
                      ]}
                      options={{
                        placeholder: "select an option",
                      }}
                      name="title"
                      className="form-control"
                      value={state.fields.title}
                      onChange={handleInputChange}
                    />
                  </div>
                </div>
                <div className="form-group">
                  <label htmlFor="productname" className="form-label">First Name</label>
                  <div className="form-control-wrap">
                    <input type="text" name="first_name" maxLength={10} className="form-control py-2 h-14  x-5" id="txtfirstname" placeholder="Enter first name"
                      value={state.fields.first_name}
                      onChange={handleInputChange} />
                  </div>
                </div>

                <div className="form-group">
                  <label htmlFor="productname" className="form-label">Last Name</label>
                  <div className="form-control-wrap">
                    <input type="text" name="last_name" maxLength={10} className="form-control py-2 h-14  x-5" id="txtlastname" placeholder="Enter last name"
                      value={state.fields.last_name}
                      onChange={handleInputChange} />
                  </div>
                </div>

                <div className="form-group">
                  <label htmlFor="productname" className="form-label">Phone</label>
                  <div className="form-control-wrap">
                    <input type="text" name="phone" maxLength={10} className="form-control py-2 h-14  x-5" id="txtphone" placeholder="Enter phone number"
                      value={state.fields.phone}
                      onChange={handleInputChangeNumber} />
                  </div>
                </div>
                <div className="form-group">
                  <label htmlFor="productname" className="form-label">Qualification</label>
                  <div className="form-control-wrap">
                    <input type="text" name="qualification" maxLength={200} className="form-control py-2 h-14  x-5" id="txtqualification" placeholder="Enter qualification"
                      value={state.fields.qualification}
                      onChange={handleInputChange} />
                  </div>
                </div>
                <div className="form-group">
                  <label htmlFor="productname" className="form-label">{state.fields.role_id === "6" ? "Organisation" : "Institute"}</label>
                  <div className="form-control-wrap">
                    <input type="text" name="organisation" maxLength={200} className="form-control py-2 h-14  x-5" id="txtorganisation"
                      placeholder={state.fields.role_id === "6" ? "Enter organisation" : "Enter institute"}
                      value={state.fields.organisation}
                      onChange={handleInputChange} />
                  </div>
                </div>

                <div className="form-group">
                  <label htmlFor="productname" className="form-label">Password</label>
                  <div className="form-control-wrap">
                    <input name="password" type="password" className="form-control  py-2 h-14  x-5" id="txtpassword" placeholder="Enter password" value={state.fields.password}
                      onChange={handleInputChange} />
                  </div>
                </div>
                <div className="form-group">
                  <label htmlFor="productname" className="form-label">Confirm Password</label>
                  <div className="form-control-wrap">
                    <input name="confirmpassword" type="password" className="form-control  py-2 h-14  x-5" id="txtconfirmpassword" placeholder="Enter password again" value={state.fields.confirmpassword}
                      onChange={handleInputChange} />
                  </div>
                </div>
                <p className="mt-6 text-center text-[15px] leading-loose text-gray-500 md:mt-7 lg:mt-9 lg:text-base">
                  By registering, you agree to our  <a className="font-semibold text-gray-700 transition-colors hover:text-primary" href={"https://FriendsupAdmintsdc.in/#/terms"} >terms and conditions</a>.
                </p>

                <button type="button" className="rizzui-button inline-flex font-medium items-center justify-center active:enabled:translate-y-px focus:outline-none focus-visible:ring-2 focus-visible:ring-opacity-50 transition-colors duration-200 px-8 py-2.5 text-base h-14 rounded-md border border-transparent focus-visible:ring-offset-2 bg-gray-900 hover:enabled::bg-gray-800 active:enabled:bg-gray-1000 focus-visible:ring-gray-900/30 text-gray-0 w-full"
                  id="btnregister" onClick={saveClicked}> {loading ? 'Authenticating...' : 'Register'}</button>
              </div>
            </form>
            <p className="mt-6 text-center text-[15px] leading-loose text-gray-500 md:mt-7 lg:mt-9 lg:text-base">
              Do you have an account? <a className="font-semibold text-gray-700 transition-colors hover:text-primary" href={"/"} >Login now</a>
            </p>
          </div>
        </div>
        <footer className="footerdisplay flex flex-col-reverse items-center justify-between px-4 py-5 lg:flex-row lg:px-16 lg:py-6 2xl:px-24 2xl:py-10 .footerpadding">
          <div className="text-center leading-relaxed text-gray-500 lg:text-start">
            © Copyright 2024.  <a className="font-medium transition-colors hover:text-primary" >FriendsupAdmin</a>, all rights reserved.
          </div>
          <div className="-mx-2.5 flex items-center justify-end pb-3 font-medium footercenter text-gray-700 lg:w-1/2 lg:pb-0"><a className="px-2.5 py-1.5 transition-colors hover:text-primary" href="https://FriendsupAdmintsdc.in/">Visit Website</a><a className="px-2.5 py-1.5 transition-colors hover:text-primary" href="https://FriendsupAdmintsdc.in/#/privacy" >Privacy</a><a className="px-2.5 py-1.5 transition-colors hover:text-primary" href="https://FriendsupAdmintsdc.in/#/terms" >Terms</a></div>
        </footer>
      </div>
      {/* ====================================================== Toast message ====================================================== */}
      {state.show_toast && (
        <div
          className="toast align-items-center text-bg-primary border-0 fade show toastmsg"
          role="alert"
          aria-live="assertive"
          aria-atomic="true"
        >
          <div className="d-flex">
            <div className="toast-body">{state.toast_msg}</div>
            <button
              type="button"
              className="btn-close btn-close-white me-2 m-auto"
              data-bs-dismiss="toast"
              onClick={(e) => hideToast()}
              aria-label="Close"
            ></button>
          </div>
        </div>
      )}


    </>
  );
};

export default authLayout(Register);
