import React from "react";
import { useNavigate, useLocation } from "react-router-dom";
import userimage from "../images/avatar/a.jpg";

const Header = () => {
    const navigate = useNavigate(); 
    const handleLogout = () => { 
        localStorage.removeItem("token"); 
        localStorage.removeItem("user_id"); 
        localStorage.removeItem("institute_id"); 
        localStorage.removeItem("user"); 
        localStorage.removeItem("role_id"); 
        localStorage.removeItem("username");   
        navigate(`/`, {  }); 
    };

    return (
        <div className="nk-header nk-header-fixed">
            <div className="container-fluid">
                <div className="nk-header-wrap">
                    <div className="nk-header-logo ms-n1">
                        <div className="nk-sidebar-toggle">
                            <button className="btn btn-sm btn-icon btn-zoom sidebar-toggle d-sm-none">
                                <em className="icon ni ni-menu"></em>
                            </button>
                            <button className="btn btn-md btn-icon btn-zoom sidebar-toggle d-none d-sm-inline-flex">
                                <em className="icon ni ni-menu"></em>
                            </button>
                        </div>
                        <a href="#" className="logo-link">
                            <div className="logo-wrap">
                                <img className="logo-svg1" src="images/friendsup.png" alt="FriendsupAdmin Logo" />
                            </div>
                        </a>
                    </div>
                    <nav className="nk-header-menu nk-navbar">
                        <div className="tbox">
                            <em className="icon ni ni-search"></em>
                            <input type="text" name="txtsearch" className="tbox-input" placeholder="Find something" value="" />
                            <span className="tbox-search">Search</span>
                        </div>
                    </nav>
                    <div className="nk-header-tools">
                        <ul className="nk-quick-nav ms-2">
                            <li>
                                <button className="btn btn-icon btn-sm d-sm-none" data-bs-toggle="offcanvas" data-bs-target="#notificationOffcanvas1">
                                    <em className="icon ni ni-arrow-from-left"></em> Logout
                                </button>
                                <button className="btn btn-icon btn-md d-none d-sm-inline-flex" onClick={handleLogout}>
                                    <em className="icon ni ni-arrow-from-left"></em> Logout
                                </button>
                            </li>
                        </ul>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default Header;
