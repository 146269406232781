import React, { useState, useEffect, useCallback } from "react";
import { Link } from 'react-router-dom';
import authLayout from "../hoc/authLayout";
import logoimage from "../assets/images/friendsup.png";
import Select2 from "react-select2-wrapper";
import userimage from "../images/userimg.jpg";
import badgesvg from "../images/badge.svg";

import emptyrecord from "../assets/images/empty.png";
import faviconimg from "../images/favicon.png";
import axios from "axios";
import baseURL from "../common/base-urls.js";
import "react-select2-wrapper/css/select2.css";
import debounce from "lodash/debounce";
import useSWR, { mutate } from "swr";
import { useNavigate, useLocation } from "react-router-dom";
import { update } from "lodash";

const BookSlot = () => {
  const navigate = useNavigate();
  const pageData = useLocation();
  const [apiUrl, setApiUrl] = useState('');
  const [apiUrlInfo, setApiUrlInfo] = useState('');
  const [slotapiUrl, slotsetApiUrl] = useState('');
  const [loading, setLoading] = useState(false);
  const [price, setPrice] = useState(true);
  const [noofreg, setnoofreg] = useState(true);
  const [activeTab, setActiveTab] = useState('findUser');
  const [mycourse, setMyCourse] = useState(false);
  const [mytrans, setMyTrans] = useState(false);
  const [showBelowCode, setShowBelowCode] = useState(false);
  const [selectionCode, setSelectionCode] = useState(false);
  const [checkoutCode, setCheckoutCode] = useState(false);
  const [showUserCode, setShowUserCode] = useState(false);
  const [showRateSection, setShowRateSection] = useState(false);
  const [showTransactionSection, setShowTransactionSection] = useState(false);
  const [showTransactionCheckout, setShowTransactionCheckout] = useState(false);
  const [showTransactionDetails, setShowTransactionDetails] = useState(false);
  const [selectedSlotId, setSelectedSlotId] = useState(null);
  const [tblmycourse, setTblMyCourse] = useState(null);
  const [tblmytrans, setTblMyTrans] = useState(null);
  const [selectedOption, setSelectedOption] = useState(''); // State to track selected option


  useEffect(() => {
    const currentTime = new Date();
    const formattedTime = currentTime.toLocaleTimeString([], { hour: '2-digit', minute: '2-digit' });
    //console.log(`=======================================`);
    //console.log(`========= Updated on ${formattedTime} =========`);
    //console.log(`=======================================`);
    //console.log(pageData);
    return () => {
    };
  }, [pageData]);
  //#region ====================================================== Create State =====================================================
  const [state, setState] = useState({
    show_toast: false,
    toast_msg: "No record found",
    show_modal: false,
    saved: false,
    filter_applied: false,
    checkbeforepayment: false,
    applycoupon: false,
    is_edit: false,
    user_name: "",
    imgSrc: "",
    show_price: "N/A",
    price_list: [],
    noofreg_list: [],
    price_list_id: [],
    price_list_id_p: [],
    final_amount: 0,
    noofreg: 1,
    noofregselected: 0,
    discount_amount: 0,
    studorprof: 1,
    data_assigned: false,
    modal_title: "Confirmation",
    modal_msg:
      "Are you absolutely certain you want to delete this record? This action cannot be reversed.",
    modal_button: "Delete",
    searchteacher: {
      phone: "",
      name: "",
      usercode: "",
      usertypeid: 2,
    },
    searchteacherAPI: {
      phone: "",
      name: "",
      usercode: "",
      usertypeid: 2,
    },
    rate_now: {
      course_id: "",
      booking_id: "",
      review_id: "",
      slot_id: "",
      course_name: "",
      rating: 0,
      review: "",
    },
    teacher: {
      phone: "",
      first_name: "",
      last_name: "",
      role_id: "",
      title: "",
      email: "",
      institute_id: "",
      new_class: [],
      prev_class: [],
    },
    course: {
      course_id: "",
      course_name: "",
      course_key: ""
    },
    courseAPI: {
      course_id: "",
      course_name: "",
      course_key: ""
    },
    user_fields: {
      selected_price: "",
      firstname: "",
      lastname: "",
      mobileno: "",
      email: "",
    },
    user: [],
    transuser: [],
    on_success: {
      course_name: "",
      student_name: "",
      amount: 0,
      is_success: false,
      order_id: "",
      payment_id: "",
      slot_on: "",
      date: "",
      payment_method: "",
      booking_count: ""
    },
    mycourse_trans: {
      course_name: "",
      student_name: "",
      amount: 0,
      is_success: false,
      order_id: "",
      payment_id: "",
      slot_on: "",
      date: "",
      payment_method: "",
      booking_count: ""
    },
    course_slot: {
      course_id: "",
      course_name: "",
      course_key: "",
      slot_id: "",
      slot_on: "",
      booking_type: "",
      amount: "",
      users: "",
    },
    fields: {
      designation_name: "",
      is_active: true,
      selected_price: "0",
      coupon_code: ""
    },
    apifields: {
      designation_name: "",
      is_active: true,
      selected_price: "0",
      coupon_code: ""
    },
    oldfields: {
      designation_name: "",
      user_name: "",
      is_active: true,
      selected_price: "0",
      coupon_code: ""
    },
  });
  //#endregion

  // Event handler for radio button change
  const handleOptionChange = (event) => {
    setSelectedOption(event.target.value);
  };
  //#region ====================================================== Shortcuts =====================================================
  document.addEventListener('keydown', function (event) {
    // Check if the key combination is Ctrl + S
    if (event.ctrlKey && event.key === 's') {
      // Prevent the default browser save behavior
      event.preventDefault();
      // Trigger the click event on your save button
      var saveButton = document.getElementById('btnsave');
      if (saveButton) {
        saveButton.click();
      }
    }
  });
  //#endregion
  //#region ====================================================== Update State ======================================================
  const updateState = (key, value) => {
    setState((prevState) => ({
      ...prevState,
      [key]: value,
    }));
  };
  //#endregion 
  //#region ====================================================== Create or Edit Record ======================================================

  useEffect(() => {
    const getData = async () => {
      try {
        let getUser = localStorage.getItem("username");
        const profileSmall = localStorage.getItem('profile_small');
        const imgSrc = profileSmall || '../images/userimg.jpg';
        updateState("imgSrc", imgSrc);

        if (getUser) {
          updateState("user_name", getUser);

        }
        // setSelectedOption("student");
        // const newApiUrl = `course/dropdown?institute_id=1`;
        // setApiUrl(newApiUrl);

        // const result = await getAPI(`course/dropdown?institute_id=1`, false);
        // if (result && result.statusCode === 200 && result.success === true) {
        //   tbldata = result;
        // } else {
        //   showToast("Something went wrong. Please try again later.");
        // }
      } catch (err) {
      }
    };
    getData();

  }, []);

  //#endregion 
  //#region ====================================================== Input Textbox ======================================================
  const handleInputChange = (event) => {
    const { name, value } = event.target;
    setState((prevState) => ({
      ...prevState,
      fields: {
        ...prevState.fields,
        [name]: value,
      },
    }));
  };
  const handleTextareaChange = (event) => {
    const { name, value } = event.target;
    setState((prevState) => ({
      ...prevState,
      rate_now: {
        ...prevState.rate_now,
        review: value,
      },
    }));
  };
  //#endregion
  //#region ====================================================== Checkbox Handler ======================================================
  const handleCheckboxActiveClick = () => {
    setState((prevState) => ({
      ...prevState,
      fields: {
        ...prevState.fields,
        is_active: !prevState.fields.is_active,
      },
    }));
  };
  //#endregion
  //#region ====================================================== Multi Select2 Dropdown ======================================================
  const handleSelectMultipleChange = (event) => {
    const selectedOptions = Array.from(
      event.target.selectedOptions,
      (option) => option.value
    );
    setState((prevState) => ({
      ...prevState,
      fields: {
        ...prevState.fields,
        [event.target.name]: selectedOptions,
      },
    }));
  };
  //#endregion
  //#region ====================================================== Date validation ======================================================
  const handleDateChange = (event) => {
    const { name, value } = event.target;
    const { start_date, end_date } = state.fields;
    const isValidDate = /^\d{4}-\d{2}-\d{2}$/.test(value);
    if (!isValidDate) {
      showToast("Invalid date format");
      return;
    }
    if (name === "start_date" && new Date(value) > new Date(end_date)) {
      showToast("Start date cannot be greater than end date");
      return;
    }
    if (name === "end_date" && new Date(value) < new Date(start_date)) {
      showToast("End date cannot be less than start date");
      return;
    }
    // Update the state
    setState((prevState) => ({
      ...prevState,
      fields: { ...prevState.fields, [name]: value },
    }));
  };
  //#endregion
  //#region ====================================================== Clear or Create/Edit Record ======================================================
  const areObjectsEqual = (obj1, obj2) => {
    const keys1 = Object.keys(obj1);
    const keys2 = Object.keys(obj2);

    if (keys1.length !== keys2.length) {
      return false;
    }

    for (const key of keys1) {
      const val1 = obj1[key];
      const val2 = obj2[key];

      if (typeof val1 === 'object' && typeof val2 === 'object') {
        if (!areObjectsEqual(val1, val2)) {
          return false;
        }
      } else if (val1 !== val2) {
        return false;
      }
    }

    return true;
  };
  useEffect(() => {
    if (state.saved === true) {
      if (state.is_edit === false) {
        const saveData = async () => {
          try {
            //console.log("state.oldfields")
            //console.log(state.oldfields)
            //console.log("state.apifields")
            //console.log(state.apifields)
            if (!areObjectsEqual(state.oldfields, state.apifields)) {
              //  const newapiFields = state.apifields;
              const newapiFields = { ...state.apifields };
              const result = await postAPI('designation', newapiFields, true);
              if (result && result.statusCode === 201 && result.success === true) {
                showToast("Record has been added successfully.");
                clearData();
              } else {
                // showToast("Something went wrong. Please try again later.");
              }
            } else {
              showToast("Data has not changed. Please add unique records.");
            }
          } catch (err) {
          }
        };
        saveData();
        updateState("saved", false);
      }
      else {

        const editData = async () => {
          try {
            //console.log("state.apifields");
            //console.log(state.apifields);
            const newapiFields = { ...state.apifields };
            const result = await putAPI('designation', newapiFields, false);
            if (result && result.statusCode === 200 && result.success === true) {
              showToast("Record has been updated successfully.");
              clearData();
            } else {
              // showToast("Something went wrong. Please try again later.");
            }

          } catch (err) {
          }
        };
        editData();
        updateState("saved", false);
        updateState("is_edit", false);
        navigate("/designationlist");
      }
    }
  }, [state.saved]);
  const saveClicked = () => {
    if (state.fields.designation_name) {
      setState((prevState) => ({
        ...prevState,
        saved: false
      }));

      setState((prevState) => ({
        ...prevState,
        saved: true,
        apifields: {
          ...prevState.apifields,
          designation_name: prevState.fields.designation_name,
          is_active: prevState.fields.is_active
        },
      }));
    }
    else {
      showToast("Designation title cannot be empty.");
    }
    window.scrollTo({ top: 0, behavior: 'smooth' });
  };
  const debouncedSaveClicked = debounce(saveClicked, 500);

  //#endregion
  //#region ====================================================== Back or FAQ ======================================================
  const Back = () => {
    try {
      window.scrollTo({ top: 0, behavior: 'smooth' });
      navigate("/designationlist");
    } catch (err) { }
  };
  const debouncedBack = debounce(Back, 500);
  const FAQ = () => {
    try {

      window.scrollTo({ top: 0, behavior: 'smooth' });
      navigate("/FAQ");
    } catch (err) { }
  };
  const debouncedFAQ = debounce(FAQ, 500);
  //#endregion
  //#region ====================================================== Toast Message set ======================================================
  const showToast = (message) => {
    updateState("show_toast", true);
    updateState("toast_msg", message);
    setTimeout(() => {
      updateState("show_toast", false);
    }, 5000);
  };
  const hideToast = () => {
    updateState("show_toast", false);
  };
  //#endregion
  //#region ====================================================== Get API ======================================================
  const getAPI = async (url, Auth) => {
    try {
      let getUser = localStorage.getItem("user");
      if (getUser) {
        getUser = JSON.parse(getUser);
        if (Auth) {
          url += `&user_id=${getUser.user_id}`;
        }
        const response = await axios.get(`${baseURL.serverUrl}${url}`, {
          headers: {
            "x-access-token": getUser.token,
            Authorization: `Bearer ${getUser.token}`,
          },
        });
        return response.data;
      } else {
        return {};
      }
    } catch (err) {
      return {};
    }
  };
  //#endregion
  //#region ====================================================== Post API ======================================================
  const postAPI = async (url, data, Auth) => {
    try {
      let getUser = localStorage.getItem("user");
      if (getUser) {
        getUser = JSON.parse(getUser);
        if (Auth) {
          // Pass user_id through data
          data.user_id = getUser.user_id;
        }
        const response = await axios.post(`${baseURL.serverUrl}${url}`, data, {
          headers: {
            "x-access-token": getUser.token,
            Authorization: `Bearer ${getUser.token}`,
            'Content-Type': 'application/json',
          },
        });
        return response.data;
      } else {
        return {};
      }
    } catch (err) {
      return {};
    }
  };
  //#endregion 
  //#region ====================================================== Post API ======================================================
  const putAPI = async (url, data, Auth) => {
    try {
      let getUser = localStorage.getItem("user");
      if (getUser) {
        getUser = JSON.parse(getUser);
        if (Auth) {
          data.user_id = getUser.user_id;
        }
        //console.log("inside put data");
        //console.log(data);
        const response = await axios.put(`${baseURL.serverUrl}${url}`, data, {
          headers: {
            "x-access-token": getUser.token,
            Authorization: `Bearer ${getUser.token}`,
            'Content-Type': 'application/json',
          },
        });
        //console.log(response)
        return response.data;
      } else {
        return {};
      }
    } catch (err) {
      return {};
    }
  };
  const putAllAPI = async (url, data, Auth) => {
    try {
      let getUser = localStorage.getItem("user");
      if (getUser) {
        getUser = JSON.parse(getUser);
        if (Auth) {
          data.user_id = getUser.user_id;
        }
        //console.log("inside put data");
        //console.log(data);
        const response = await axios.put(`${baseURL.serverUrl}${url}/${state.rate_now.review_id}`, data, {
          headers: {
            "x-access-token": getUser.token,
            Authorization: `Bearer ${getUser.token}`,
            'Content-Type': 'application/json',
          },
        });
        return response.data;
      } else {
        return {};
      }
    } catch (err) {
      return {};
    }
  };


  //#endregion 
  //#region ====================================================== Put API ======================================================

  const handleCustomCourseClick = (response_id) => {
    setShowTransactionDetails(true);
  };

  const handleTransCancelIconClick = () => {
    setSelectedSlotId(null);
    setShowBelowCode(false);
    setSelectionCode(false);
    setShowTransactionCheckout(false);
  };

  const handleRateNowClick = (course_id, course_name, booking_id, review_id, slot_id, rating, review) => {

    setState((prevState) => ({
      ...prevState,
      rate_now: {
        course_id: course_id,
        booking_id: booking_id,
        review_id: review_id,
        slot_id: slot_id,
        course_name: course_name,
        rating: rating,
        review: review,
      }
    }));
    setShowRateSection(true);
  };

  const handleViewTransactionClick = (response_id) => {
    setShowTransactionSection(true);
    setShowRateSection(false);
  };

  const handleMyCancelIconClick = () => {
    setShowRateSection(false);
    setShowTransactionSection(false);
    setState((prevState) => ({
      ...prevState,
      transuser: [],
      mycourse_trans: {
        course_name: "",
        student_name: "",
        amount: 0,
        is_success: false,
        order_id: "",
        payment_id: "",
        slot_on: "",
        date: "",
        payment_method: "",
        booking_count: ""
      }
    }));
  };

  const handleMyTransCancelIconClick = () => {
    setShowRateSection(false);
    setShowTransactionSection(false);
  };

  let tblslot = {};
  useEffect(() => {
    if (state.course.course_id != "") {

      const getData = async () => {
        try {
          //console.log("inside slot");
          const newApiUrl = `courseslot/dropdown?institute_id=1` + "&course_id=" + state.course.course_id;
          slotsetApiUrl(newApiUrl);
          setPrice(false);
        } catch (err) {
        }
      };
      getData();

    }
  }, [state.course]);


  const { data: dataslot, errorslot, isValidatingslot } = useSWR(
    slotapiUrl,
    () => getAPI(slotapiUrl, false),
    {
      revalidateOnReconnect: false,
      revalidateOnFocus: false,
    }
  );
  tblslot = dataslot;

  useEffect(() => {
    if (price === false && tblslot && tblslot.data && tblslot.data.coursedetails) {
      const getData = async () => {
        try {
          let ddprice = [
            { "ddname": "As Student", "ddvalue": 1 },
            { "ddname": "As Professional", "ddvalue": 2 },
            { "ddname": "For Professional Group of 2 to 3", "ddvalue": 3 },
            { "ddname": "For Professional Group of 4 to 6", "ddvalue": 4 },
            { "ddname": "For Professional Group of 7 to 9", "ddvalue": 5 },
            { "ddname": "For Professional Group more than 10", "ddvalue": 6 },
            { "ddname": "For Student Group of 2 to 3", "ddvalue": 7 },
            { "ddname": "For Student Group of 4 to 6", "ddvalue": 8 },
            { "ddname": "For Student Group of 7 to 9", "ddvalue": 9 },
            { "ddname": "For Student Group more than 10", "ddvalue": 10 },
          ];

          let ddnoofreg = [
            { "ddname": "1", "ddvalue": 1 },
            { "ddname": "2", "ddvalue": 2 },
            { "ddname": "3", "ddvalue": 3 },
            { "ddname": "4", "ddvalue": 4 },
            { "ddname": "5", "ddvalue": 5 },
            { "ddname": "6", "ddvalue": 6 },
            { "ddname": "7", "ddvalue": 7 },
            { "ddname": "8", "ddvalue": 8 },
            { "ddname": "9", "ddvalue": 9 },
            { "ddname": "10", "ddvalue": 10 },
            { "ddname": "11", "ddvalue": 11 },
            { "ddname": "12", "ddvalue": 12 },
          ];

          updateState("price_list", ddprice);
          updateState("noofreg_list", ddnoofreg);


          let ddprice1 = [
            { "ddname": "1", "ddvalue": tblslot.data.coursedetails.s_amount },
            { "ddname": "2", "ddvalue": tblslot.data.coursedetails.s3_amount },
            { "ddname": "3", "ddvalue": tblslot.data.coursedetails.s3_amount },
            { "ddname": "4", "ddvalue": tblslot.data.coursedetails.s6_amount },
            { "ddname": "5", "ddvalue": tblslot.data.coursedetails.s6_amount },
            { "ddname": "6", "ddvalue": tblslot.data.coursedetails.s6_amount },
            { "ddname": "7", "ddvalue": tblslot.data.coursedetails.s9_amount },
            { "ddname": "8", "ddvalue": tblslot.data.coursedetails.s9_amount },
            { "ddname": "9", "ddvalue": tblslot.data.coursedetails.s9_amount },
            { "ddname": "10", "ddvalue": tblslot.data.coursedetails.s12_amount },
            { "ddname": "11", "ddvalue": tblslot.data.coursedetails.s12_amount },
            { "ddname": "12", "ddvalue": tblslot.data.coursedetails.s12_amount },
          ];
          updateState("price_list_id", ddprice1);
          let ddprice1p = [
            { "ddname": "1", "ddvalue": tblslot.data.coursedetails.p_amount },
            { "ddname": "2", "ddvalue": tblslot.data.coursedetails.g3_amount },
            { "ddname": "3", "ddvalue": tblslot.data.coursedetails.g3_amount },
            { "ddname": "4", "ddvalue": tblslot.data.coursedetails.g6_amount },
            { "ddname": "5", "ddvalue": tblslot.data.coursedetails.g6_amount },
            { "ddname": "6", "ddvalue": tblslot.data.coursedetails.g6_amount },
            { "ddname": "7", "ddvalue": tblslot.data.coursedetails.g9_amount },
            { "ddname": "8", "ddvalue": tblslot.data.coursedetails.g9_amount },
            { "ddname": "9", "ddvalue": tblslot.data.coursedetails.g9_amount },
            { "ddname": "10", "ddvalue": tblslot.data.coursedetails.g12_amount },
            { "ddname": "11", "ddvalue": tblslot.data.coursedetails.g12_amount },
            { "ddname": "12", "ddvalue": tblslot.data.coursedetails.g12_amount },
          ];
          updateState("price_list_id_p", ddprice1p);



          setPrice(true);
        } catch (err) {
        }
      };
      getData();
    }
  }, [tblslot, price]);



  const handleCheckoutClick = (courseId, courseName, courseKeyPoints) => {
    // const updatedapifieldsState = {
    //   course_id: courseId,
    //   course_name: courseName,
    //   course_key: courseKeyPoints,
    //   slot_id: "",
    //   slot_on: "",
    //   booking_type: "",
    //   amount: "",
    //   users: ""
    // };
    // updateState("course", updatedapifieldsState);
    setCheckoutCode(true);
    setSelectionCode(false);
  };

  const handleCheckoutCancelIconClick = () => {
    setCheckoutCode(false);
    setSelectionCode(true);
  };

  const handleCouponCancelIconClick = () => {
    setState((prevState) => ({
      ...prevState,
      final_amount: 0,
      discount_amount: 0,
      applycoupon: false
    }));
  };


  const handleSlotClick = (slotId, slotOn) => {
    setState((prevState) => ({
      ...prevState,
      course_slot: {
        course_id: state.course.course_id,
        course_name: state.course.course_name,
        course_key: state.course.course_key,
        slot_id: slotId,
        slot_on: slotOn
      },
    }));
    setSelectedSlotId(slotId);
    // setShowUserCode(true);
  };


  const handleCancelIconClick = () => {
    setShowBelowCode(false);
    setSelectionCode(false);
    setShowTransactionCheckout(false);
  };
  const handleTabClick = (tab) => {
    setActiveTab(tab);
    if (tab == "classList") {
      setMyCourse(true);
    }
    else if (tab == "findStudent") {
      setMyTrans(true);
    }
  };
  const handleLogout = () => {
    localStorage.removeItem("token");
    localStorage.removeItem("user_id");
    localStorage.removeItem("institute_id");
    localStorage.removeItem("user");
    navigate(`/`, {});
  };
  function formatDate(slotOn) {
    const slotDate = new Date(slotOn);

    // Define options for formatting the date
    const dateOptions = { weekday: 'short', month: 'short', day: 'numeric', timeZone: 'UTC' };

    // Format the date
    return slotDate.toLocaleDateString('en-US', dateOptions);
  }

  // function formatTime(slotOn) {
  //   const slotDate = new Date(slotOn);

  //   // Define options for formatting the time
  //   const timeOptions = { hour: 'numeric', minute: 'numeric', hour12: true };

  //   // Format the time
  //   return slotDate.toLocaleTimeString('en-US', timeOptions);
  // }
  function formatTime(slotOn) {
    const slotDate = new Date(slotOn);

    // Define options for formatting the time
    const timeOptions = { hour: 'numeric', minute: 'numeric', hour12: true, timeZone: 'UTC' };

    // Format the time
    return slotDate.toLocaleTimeString('en-US', timeOptions);
  }


  // useEffect(() => {
  //   //console.log("state.user")
  //   //console.log(state.course_slot)
  //   //console.log(state.user)
  //   if (state.payclicked === true && 1 == 2) {
  //     const saveData = async () => {
  //       try {
  //         const newapiFields = { ...state.course_slot };
  //         const userinfo = { ...state.user };
  //         const result = await postAPI('bookingapi', newapiFields, true);
  //         if (result && result.statusCode === 201 && result.success === true) {
  //           showToast("Record has been added successfully.");
  //           clearData();
  //         } else {
  //           showToast("Something went wrong. Please try again later.");
  //         }

  //       } catch (err) {
  //       }
  //     };
  //     saveData();
  //     updateState("payclicked", false);
  //   }
  // }, [state.payclicked]);
  const payClicked = () => {
    const { selected_price } = state.fields;
    const { slot_id } = state.course_slot;
    let userinfo = [];
    handleCouponCancelIconClick();
    if (selected_price != "" && slot_id != "") {
      userinfo = collectUserData();
      if (userinfo === null) {
        showToast("Please enter firstname, lastname and phone number of attendee(s)");
        return;
      }
      setState((prevState) => ({
        ...prevState,
        payclicked: false
      }));

      setState((prevState) => ({
        ...prevState,
        payclicked: true,
        user: userinfo,
      }));

      setCheckoutCode(true);
      setSelectionCode(false);
    }
    else {
      showToast("Please select suitable slot and booking type");
    }
    window.scrollTo({ top: 0, behavior: 'smooth' });
  };
  const debouncedCheckoutClicked = debounce(payClicked, 500);

  useEffect(() => {
    if (state.applycoupon === true) {
      const saveData = async () => {
        try {
          let urldata = "?course_id=" + state.course_slot.course_id + "&coupon_code=" + state.fields.coupon_code;
          const result = await getAPI(`course/coupon` + urldata, false);
          if (result && result.statusCode === 200 && result.success === true) {
            //console.log(result);
            if (result.data.is_applied == true) {
              if (result.data.coupon_perc) {
                let discountAmount = ((parseInt(state.show_price) * state.user.length) * result.data.coupon_perc) / 100;
                const final_amount = Math.round((parseInt(state.show_price) * state.user.length) - discountAmount);
                discountAmount = (parseInt(state.show_price) * state.user.length) - final_amount;
                setState((prevState) => ({
                  ...prevState,
                  final_amount: final_amount,
                  discount_amount: discountAmount,
                  fields: {
                    ...prevState.fields,
                    coupon_code: "",
                  },
                }));
              }
            }
            showToast(result.data.coupon_message);
          } else {
            // showToast("Not applie");
          }
        } catch (err) {
        }
      };
      saveData();
      updateState("applycoupon", false);
    }
  }, [state.applycoupon]);
  const applyCoupon = () => {
    const { coupon_code } = state.fields;
    if (coupon_code != "" && coupon_code.length == 6) {
      setState((prevState) => ({
        ...prevState,
        applycoupon: false
      }));
      setState((prevState) => ({
        ...prevState,
        applycoupon: true
      }));
    }
  };
  const debouncedApplyclicked = debounce(applyCoupon, 500);

  const validateFields = () => {
    const { firstname, lastname, mobileno, email } = state.fields;
    if (!firstname || !lastname || !mobileno || !email) {
      // Check if required fields are empty
      alert("All fields are required");
      return false;
    }
    if (!/^\d{10}$/.test(mobileno)) {
      // Check if mobile number is valid
      alert("Mobile number must be 10 digits");
      return false;
    }
    if (!/\S+@\S+\.\S+/.test(email)) {
      // Check if email is valid
      alert("Invalid email address");
      return false;
    }
    return true;
  };

  useEffect(() => {
    const validPrices = ["1", "2", "3", "4", "5", "6", "7", "8", "9", "10", "11", "12"];
    if (validPrices.includes(state.fields.selected_price)) {
      // if (state.fields.selected_price === "1" || state.fields.selected_price === "2" || state.fields.selected_price === "3" || state.fields.selected_price === "4" || state.fields.selected_price === "5" || state.fields.selected_price === "6") {
      const amount = getAmountForSelectedValue(state.fields.selected_price);
      updateState("show_price", amount);
    }
  }, [state.fields.selected_price, selectedOption]);


  const renderAdditionalFields = () => {
    const { selected_price } = state.fields;
    const validPrices = ["2", "3", "4", "5", "6", "7", "8", "9", "10", "11", "12"];
    if (validPrices.includes(state.fields.selected_price)) {

      //   if (selected_price === "3" || selected_price === "4" || selected_price === "5" || selected_price === "6") {
      let numUsers = 0;
      numUsers = selected_price;
      // if (selected_price === "3") {
      //   numUsers = 3;
      // }
      // else if (selected_price === "4") {
      //   numUsers = 6;
      // }
      // else if (selected_price === "5") {
      //   numUsers = 9;
      // }
      // else if (selected_price === "6") {
      //   numUsers = 12;
      // } else if (selected_price === "7") {
      //   numUsers = 3;
      // }
      // else if (selected_price === "8") {
      //   numUsers = 6;
      // }
      // else if (selected_price === "9") {
      //   numUsers = 9;
      // }
      // else if (selected_price === "10") {
      //   numUsers = 12;
      // } 
      const fields = [];
      for (let i = 0; i < numUsers; i++) {
        fields.push(
          <React.Fragment key={i}>
            <span className="txtspan">Attendee {i + 1} details</span>
            <input
              type="text"
              placeholder={`Firstname`}
              maxlength={50}
              className="form-control txtbox"
              value={state.fields[`firstname${i + 1}`]}
              onChange={(e) =>
                setState((prevState) => ({
                  ...prevState,
                  fields: {
                    ...prevState.fields,
                    [`firstname${i + 1}`]: e.target.value,
                  },
                }))
              }
            />
            <input
              type="text"
              placeholder={`Lastname`}
              maxlength={50}
              className="form-control txtbox"
              value={state.fields[`lastname${i + 1}`]}
              onChange={(e) =>
                setState((prevState) => ({
                  ...prevState,
                  fields: {
                    ...prevState.fields,
                    [`lastname${i + 1}`]: e.target.value,
                  },
                }))
              }
            />
            <input
              type="text"
              placeholder={`Mobileno`}
              className="form-control txtbox"
              value={state.fields[`mobileno${i + 1}`]}
              onChange={(e) => {
                let value = e.target.value;
                // Allow only digits for mobile number
                value = value.replace(/\D/g, '');
                // Limit to max 10 characters for mobile number
                if (value.length > 10) {
                  value = value.slice(0, 10);
                }
                setState((prevState) => ({
                  ...prevState,
                  fields: {
                    ...prevState.fields,
                    [`mobileno${i + 1}`]: value,
                  },
                }));
              }}
            />
            <input
              type="email"
              placeholder={`Email`}
              className="form-control txtbox"
              value={state.fields[`email${i + 1}`]}
              onChange={(e) => {
                let value = e.target.value;
                // Email validation
                const isValidEmail = /^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(value);
                if (!isValidEmail) return; // Don't update state if email is invalid
                setState((prevState) => ({
                  ...prevState,
                  fields: {
                    ...prevState.fields,
                    [`email${i + 1}`]: value,
                  },
                }));
              }}
            />

          </React.Fragment>
        );
      }
      return <>{fields}</>;
    }
    return null;
  };

  const collectUserData = () => {
    const { selected_price } = state.fields;
    let userData = [];
    let attendeesRequired = 0;

    // Determine the number of attendees required based on the selected price
    // switch (selected_price) {
    //   case "3":
    //     attendeesRequired = 2;
    //     break;
    //   case "4":
    //     attendeesRequired = 4;
    //     break;
    //   case "5":
    //     attendeesRequired = 7;
    //     break;
    //   case "6":
    //     attendeesRequired = 10;
    //     break;
    //   case "7":
    //     attendeesRequired = 2;
    //     break;
    //   case "8":
    //     attendeesRequired = 4;
    //     break;
    //   case "9":
    //     attendeesRequired = 7;
    //     break;
    //   case "10":
    //     attendeesRequired = 10;
    //     break;
    //   default:
    //     attendeesRequired = 0;
    // }
    attendeesRequired = selected_price;
    if (selected_price == "1") {
      let getUser = localStorage.getItem("user");
      if (getUser) {
        getUser = JSON.parse(getUser);
        userData.push({
          first_name: getUser.first_name,
          last_name: getUser.last_name,
          mobile: getUser.phone,
          email: getUser.email,
        });
      }
    }
    else {
      for (let i = 0; i < (attendeesRequired); i++) {
        const firstName = state.fields[`firstname${i + 1}`];
        const lastName = state.fields[`lastname${i + 1}`];
        const mobile = state.fields[`mobileno${i + 1}`];
        const email = state.fields[`email${i + 1}`];
        // Validate data (you can add more specific validations here)
        if (!firstName || !lastName || !mobile || mobile.length != 10) {
          if ((i + 1) <= attendeesRequired) {
            return null;
          }
        }
        else {
          userData.push({
            first_name: firstName,
            last_name: lastName,
            mobile,
            email,
          });
        }
        // Push user data to the userData array

      }
    }
    // Return the array of user data if validation passes
    return userData;
  };
  const getAmountForSelectedValue = (selectedValue) => {
    let priceListId = [];
    if (selectedOption == "student") {
      priceListId = state.price_list_id;
    }
    else {
      priceListId = state.price_list_id_p;
    }
    //noofreg_list
    if (priceListId) {

      const selectedAmount = priceListId.find(item => item.ddname === selectedValue);
      return selectedAmount ? selectedAmount.ddvalue : 0; // Return 0 if no matching amount found
    }
    return 0; // Return default value if priceListId is not initialized
  };
  //#endregion 
  //#region ====================================================== Payment gateway =====================================================
  const generateRazorpayLink = async () => {
    try {
      const data = {
        amount: state.final_amount != 0 ? state.final_amount : (parseInt(state.show_price) * state.user.length),
        course_id: state.course_slot.course_id,
        slot_id: state.course_slot.slot_id,
        is_discount: state.discount_amount != 0 ? true : false,
        discount_amount: state.discount_amount != 0 ? state.discount_amount : 0,
        booking_count: state.user.length
      };
      const response = await postAPI("razorpayment/subscribe", data, false);
      return response;
    } catch (err) {
      console.error('Error:', err);
    }
  };
  const paymentVerification = async (payload, olddata) => {
    try {
      const requestData = { ...payload, user: olddata };
      const response = await postAPI("razorpayment/paymentVerification", requestData, false);
      //console.log("response");
      //console.log(response);
      if (response) {
        let getUser = localStorage.getItem("user");
        if (getUser) {
          getUser = JSON.parse(getUser);
          setShowTransactionCheckout(true);
          setSelectionCode(false);
          setCheckoutCode(false);
          setState((prevState) => ({
            ...prevState,
            on_success: {
              ...prevState.on_success,
              course_name: state.course_slot.course_name,
              student_name: getUser.first_name + " " + getUser.last_name,
              amount: response.data.amount,
              order_id: response.data.order_id,
              payment_id: response.data.payment_id,
              is_success: response.success,
              slot_on: state.course_slot.slot_on,
              date: response.data.created_at,
              payment_method: response.data.payment_method,
              booking_count: response.data.booking_count,
            },
          }));
          clearAllData();
        }
      }
      return response;
    } catch (err) {
      console.error('Error:', err);
    }
  };
  const clearAllData = () => {
    const updatedapifieldsState = {
      course_id: "",
      course_name: "",
      course_key: "",
      slot_id: "",
      slot_on: "",
      booking_type: "",
      amount: "",
      users: ""
    };
    updateState("course", updatedapifieldsState);
    setState((prevState) => ({
      ...prevState,
      final_amount: 0,
      discount_amount: 0,
      show_price: "N/A",
      applycoupon: false,
      checkbeforepayment: false,
      saved: false,
      is_edit: false,
      price_list: [],
      price_list_id: [],
      price_list_id_p: [],
      course_slot: {
        ...prevState.course_slot,
        course_id: "",
        course_name: "",
        course_key: "",
        slot_id: "",
        slot_on: "",
      },
      fields: {
        ...prevState.fields,
        selected_price: 0,
      }
    }));
    setSelectedSlotId(null);
  };
  useEffect(() => {
    if (state.checkbeforepayment === true) {
      const saveData = async () => {
        try {
          let urldata = "?course_id=" + state.course_slot.course_id + "&slot_id=" + state.course_slot.slot_id + "&regcount=" + state.user.length;
          const result = await getAPI(`course/slotcheck` + urldata, false);
          if (result && result.statusCode === 200 && result.success === true) {
            //console.log(result);
            if (result.data.can_payment == true) {
              handlePayment();
            }
            else {
              showToast(result.data.payment_message);
            }
          } else {
            showToast("Not available for booking. Please contact us directly");
            // showToast("No slots");
          }
        } catch (err) {
        }
      };
      saveData();
      updateState("checkbeforepayment", false);
    }
  }, [state.checkbeforepayment]);
  const checkBeforePayment = () => {
    setState((prevState) => ({
      ...prevState,
      checkbeforepayment: false
    }));
    setState((prevState) => ({
      ...prevState,
      checkbeforepayment: true
    }));
  };

  const debouncePayNow = debounce(checkBeforePayment, 500);

  const handlePayment = async () => {
    try {
      let getUser = localStorage.getItem("user");
      if (getUser) {
        getUser = JSON.parse(getUser);
        //slotcheck 
        // const result = await getAPI(`course/slotcheck?course_id=${state.course_slot.course_id}&slot_id=${state.course_slot.slot_id}`, false);
        // if (result && result.statusCode === 200 && result.success === true) {
        //   if (result.data.can_payment == true) {

        const data = await generateRazorpayLink();
        if (data && data.success === true) {
          const options = {
            key: "rzp_live_2o7FkoLr7vkK0A",
            amount: data.data.amount,
            currency: "INR",
            name: "FriendsupAdmin TSDC",
            image: "https://FriendsupAdmintsdc.in/static/media/FriendsupAdmin.723180779ba8269344ce.png",
            order_id: data.data.order_id,
            handler: (response) => paymentVerification(response, state.user), // Call the function directly
            prefill: {
              name: getUser.first_name + " " + getUser.last_name,
              email: getUser.email,
              contact: getUser.phone,
            },
            theme: {
              color: "#121212",
            },
          };
          const razor = new window.Razorpay(options);
          razor.open();
        }

        // }
        // else {
        //   showToast(result.data.payment_message.toString());

        // }
        // } else {
        //   showToast("Slot not available while you were adding booking details");
        // }





      }
    } catch (err) {
      console.error('Error:', err);
      // setError('Login failed. Please check your credentials.');
    }
  };
  //#endregion
  //#region ====================================================== Class list =====================================================

  // useEffect(() => {
  //   if (mycourse == true) {
  //     const getData = async () => {
  //       try {
  //         let getUser = localStorage.getItem("user");
  //         if (getUser) {
  //           getUser = JSON.parse(getUser);
  //           const result = await getAPI(`booking/course/${getUser.user_id}`, false);
  //           if (result && result.statusCode === 200 && result.success === true) {
  //             setTblMyCourse(result);
  //             handleMyCancelIconClick();
  //           } else {
  //             // showToast("Something went wrong. Please try again later.");
  //           }
  //         }
  //       } catch (err) {
  //       }

  //     };
  //     getData();
  //     setMyCourse(false);
  //   }
  // }, [mycourse]);


  const handleRatingClick = (ratingValue) => {
    setState(prevState => ({
      ...prevState,
      rate_now: {
        ...prevState.rate_now,
        rating: ratingValue
      }
    }));
  };

  const reviewSubmitClicked = () => {
    const { review, rating } = state.rate_now;
    if (review !== undefined && rating !== undefined && review !== "" && rating !== 0) {
      const saveData = async () => {
        try {
          const newapiFields = { ...state.rate_now };
          const result = await postAPI('coursereview', newapiFields, true);
          if (result && result.statusCode === 201 && result.success === true) {
            setShowRateSection(false);
            setShowTransactionSection(false);
            showToast("Thank you for sharing your feedback! Your review has been successfully submitted.");
            handleTabClick('classList');
          } else {
            showToast(result.message);
          }
        } catch (err) {

        }
      };
      saveData();
    }
    else {
      showToast("Please select star rating and write your feedback before submitting.");
    }
    window.scrollTo({ top: 0, behavior: 'smooth' });
  };
  const debouncedRateSubmitClicked = debounce(reviewSubmitClicked, 500);


  const reviewReSubmitClicked = () => {
    const { review, rating } = state.rate_now;
    if (review !== undefined && rating !== undefined && review !== "" && rating !== 0) {
      const saveData = async () => {
        try {
          const newapiFields = { ...state.rate_now };
          const result = await putAllAPI('coursereview', newapiFields, true);
          if (result && result.statusCode === 200 && result.success === true) {
            setShowRateSection(false);
            setShowTransactionSection(false);
            showToast("Your review has been updating successfully submitted.");
            handleTabClick('classList');
          } else {
            showToast(result.message);
          }
        } catch (err) {

        }
      };
      saveData();
    }
    else {
      showToast("Please select star rating and write your feedback before submitting.");
    }
    window.scrollTo({ top: 0, behavior: 'smooth' });
  };
  const debouncedRateReSubmitClicked = debounce(reviewReSubmitClicked, 500);

  const getTransaction = (responseId) => {
    //console.log("responseId");
    //console.log(responseId);
    if (responseId !== undefined && responseId != 0) {
      const saveData = async () => {
        try {
          const newapiFields = {};
          const result = await getAPI(`booking/transaction/${responseId}`, false);
          if (result && result.statusCode === 200 && result.success === true) {
            setShowTransactionSection(true);
            setShowRateSection(false);
            setState((prevState) => ({
              ...prevState,
              transuser: result.data.transdetail.user,
              mycourse_trans: result.data.transdetail,
            }));
          } else {
            showToast(result.message);
          }
        } catch (err) {

        }
      };
      saveData();
    } else {
      showToast("Please try again later.");
    }
    window.scrollTo({ top: 0, behavior: 'smooth' });
  };
  //#endregion
  //#region ====================================================== My Transactions =====================================================

  // useEffect(() => {
  //   if (mytrans == true) {
  //     const getData = async () => {
  //       try {
  //         let getUser = localStorage.getItem("user");
  //         if (getUser) {
  //           getUser = JSON.parse(getUser);
  //           const result = await getAPI(`booking/transactionlist/${getUser.user_id}`, false);
  //           if (result && result.statusCode === 200 && result.success === true) {
  //             setTblMyTrans(result);
  //             handleMyTransCancelClick();
  //           } else {
  //             // showToast("Something went wrong. Please try again later.");
  //           }
  //         }
  //       } catch (err) {
  //       }

  //     };
  //     getData();
  //     setMyTrans(false);
  //   }
  // }, [mytrans]);

  const handleMyTransCancelClick = () => {
    setShowTransactionDetails(false);
    setState((prevState) => ({
      ...prevState,
      transuser: [],
      mycourse_trans: {
        course_name: "",
        student_name: "",
        amount: 0,
        is_success: false,
        order_id: "",
        payment_id: "",
        slot_on: "",
        date: "",
        payment_method: "",
        booking_count: ""
      }
    }));

  };

  const getmyTransaction = (responseId) => {
    if (responseId !== undefined && responseId != 0) {
      const saveData = async () => {
        try {
          const newapiFields = {};
          const result = await getAPI(`booking/transaction/${responseId}`, false);
          if (result && result.statusCode === 200 && result.success === true) {
            setShowTransactionDetails(true);
            setState((prevState) => ({
              ...prevState,
              transuser: result.data.transdetail.user,
              mycourse_trans: result.data.transdetail,
            }));
          }
          else {
            showToast(result.message);
          }
        } catch (err) {
        }
      };
      saveData();
    } else {
      showToast("Please try again later.");
    }
    window.scrollTo({ top: 0, behavior: 'smooth' });
  };

  //#endregion



  //#region ====================================================== New Code =====================================================

  const handleInputChangeNumber = (event) => {
    const { name, value } = event.target;
    // Remove non-digit characters from the input value
    const newValue = value.replace(/\D/g, '');
    setState((prevState) => ({
      ...prevState,
      fields: {
        ...prevState.fields,
        [name]: newValue,
      },
    }));
  };
  const handleInputChangeNumberSearch = (event) => {
    const { name, value } = event.target;
    // Remove non-digit characters from the input value
    const newValue = value.replace(/\D/g, '');
    setState((prevState) => ({
      ...prevState,
      searchteacher: {
        ...prevState.searchteacher,
        [name]: newValue,
      },
    }));
  };

  const handleRadioChange = (e) => {
    setState((prevState) => ({
      ...prevState,
      searchteacher: {
        ...prevState.searchteacher,
        usertypeid: e.target.value
      }
    }));
  };

  const handleInputChangeSearch = (event) => {
    const { name, value } = event.target;
    setState(prevState => ({
      ...prevState,
      searchteacher: {
        ...prevState.searchteacher,
        [name]: value
      }
    }));
  };

  const applyFilter = async () => {
    try {
      const { phone, name, usercode } = state.searchteacher;
      const isValidPhone = phone && phone.length >= 3;
      const isValidName = name && name.length >= 3;
      const isValidUsercode = usercode && usercode.length >= 3;

      if (!isValidPhone && !isValidName && !isValidUsercode) {
        showToast("Please enter at least 3 characters in any of the filter fields.");
        return; // Exit the function early
      }


      setState((prevState) => ({
        ...prevState,
        filter_applied: true,
        searchteacherAPI: {
          ...prevState.searchteacherAPI,
          phone: prevState.searchteacher.phone,
          name: prevState.searchteacher.name,
          usercode: prevState.searchteacher.usercode,
          usertypeid: prevState.searchteacher.usertypeid,
        },
      }));

      //setPage(1);
    } catch (err) { }
  };
  // const clearFilter = async () => {
  //   try {
  //     const updatedapifieldsState = {
  //       institute_id: state.fields.institute_id,
  //       course_type_id: "",
  //       course_name: "",
  //       is_free: "",
  //       start_date: "",
  //       end_date: "",
  //     };
  //     const updatedfieldState = {
  //       institute_id: state.fields.institute_id,
  //       course_type_id: "",
  //       course_name: "",
  //       is_free: "",
  //       start_date: "",
  //       end_date: "",
  //     };
  //     updateState("apifields", updatedapifieldsState);
  //     updateState("fields", updatedfieldState);
  //     updateState("filter_applied", false);
  //   } catch (err) { }
  // };




  let tbldatateacher = {};
  let tblinstitutes = {};
  let apiinstitute = `institute/dropdown?page=1&limit=500`;
  useEffect(() => {
    const queryParams = {
      page: 1,
      limit: 25,
      role_id: state.searchteacher.usertypeid || "2", // Default role_id or from state
      sortby: "firstname",
      orderby: "Asc",
      phone: state.searchteacher.phone || null,
      title: state.searchteacher.name || null,
      user_code: state.searchteacher.usercode || null,
    };
    const filteredParams = Object.fromEntries(
      Object.entries(queryParams).filter(([_, value]) => value != null && value !== '')
    );
    const queryString = new URLSearchParams(filteredParams).toString();
    const newApiUrl = `admin/appuser?${queryString}`;
    setApiUrl(newApiUrl);
    if (!state.filter_applied) {
      return;
    }
    if (queryString && queryString != `page=1&limit=${50}`) {
    } else {
      showToast("Please enter/select at least one filter criteria to apply the filter.");
    }

  }, [state.searchteacherAPI]);

  const { data, error, isValidating } = useSWR(
    apiUrl,
    () => getAPI(apiUrl, false),
    {
      revalidateOnReconnect: false,
      revalidateOnFocus: false,
    }
  );
  tbldatateacher = data;

  const setSvgColor = (badgeColor) => {
    return { fill: badgeColor };
  };
  const handleInputChangef = (event) => {
    const { name, value } = event.target;
    setUserDetails((prevState) => ({
      ...prevState,
      [name]: value,
    }));
  };

  const handleInputChangeNumberf = (event) => {
    const { name, value } = event.target;
    // Optionally add validation for phone number here
    setUserDetails((prevState) => ({
      ...prevState,
      [name]: value,
    }));
  };

  // const [userDetails, setUserDetails] = useState({});
  const [userDetails, setUserDetails] = useState({
    first_name: '',
    last_name: '',
    phone: '',
    email: ''
  });
  const [classList, setClassList] = useState([]);
  const [newClass, setNewClass] = useState({
    course_id: '',
    batch_id: '',
    subject_id: '',
    class_id: '',
    course_name: '',
    batch_name: '',
    subject_name: '',
  });
  const [courses, setCourses] = useState([]);
  const [batches, setBatches] = useState([]);
  const [subjects, setSubjects] = useState([]);
  const [prevClasses, setPrevClasses] = useState([]);
  const [instituteId, setInstituteId] = useState('');

  // Fetch user details and classes
  // const fetchUserData = async (userId) => {
  //   const data = await getAPI(`/admin/teacher/${userId}`, true);
  //   return data;
  // };
  let tblapiuserinfo = [];
  const handleUserClick = (userId) => {
    setShowBelowCode(true);
    setApiUrlInfo(`admin/appuser/teacher/${userId}`);
  };
  const { data: userData } = useSWR(
    apiUrlInfo,
    () => getAPI(apiUrlInfo, false),
    {
      revalidateOnReconnect: false,
      revalidateOnFocus: false,
    }
  );
  tblapiuserinfo = userData;




  // const { data: userData, error1 } = useSWR(userId ? `/admin/teacher/${userId}` : null,
  //   () => getAPI(apiUrl, false),
  //   {
  //     revalidateOnReconnect: false,
  //     revalidateOnFocus: false,
  //   }
  // );

  // Fetch courses, batches, and subjects based on the instituteId
  useEffect(() => {

    if (userData && userData.data && userData.data.hasOwnProperty("userdetails")) {
      console.log("inside userdata");
      //if (userData && userData.data && tblapiuserinfo ) {
      const { userdetails, classlist } = userData.data;
      const transformedClassList = classlist.map(cls => ({
        class_id: cls.class_id,
        course_id: cls.course_id,
        batch_id: cls.batch_id,
        subject_id: cls.subject_id,
        course_name: cls.course.course_name,
        batch_name: cls.batch.batch_name,
        subject_name: cls.subject.subject_name,
      }));
      setUserDetails(userdetails);
      setClassList(transformedClassList);
      setPrevClasses(classlist);
      setInstituteId(userdetails.institute_id);
      // Fetch courses
      getAPI(`course?institute_id=${userdetails.institute_id}`, false)
        .then(response => {
          if (response && response.data && Array.isArray(response.data.postType)) {
            setCourses(response.data.postType);
          } else {
            console.error('Unexpected data format:', response);
            setCourses([]); // Set to empty array if data is not in expected format
          }
        })
        .catch(error => {
          console.error('Error fetching courses:', error);
          setCourses([]); // Handle error by setting to empty array
        });
    }
  }, [userData, tblapiuserinfo]);

  useEffect(() => {
    if (newClass.course_id) {
      // Fetch batches based on selected course
      getAPI(`batch/list?institute_id=${instituteId}&course_id=${newClass.course_id}`, false)
        .then(response => {
          if (response && response.data && Array.isArray(response.data.batch)) {
            setBatches(response.data.batch);
          } else {
            console.error('Unexpected data format:', response);
            setBatches([]); // Set to empty array if data is not in expected format
          }
        })
        .catch(error => {
          console.error('Error fetching batches:', error);
          setBatches([]); // Handle error by setting to empty array
        });
      // Fetch subjects based on selected course
      getAPI(`subject/list?institute_id=${instituteId}&course_id=${newClass.course_id}`, false)
        .then(response => {
          if (response && response.data && Array.isArray(response.data.subject)) {
            setSubjects(response.data.subject);
          } else {
            console.error('Unexpected data format:', response);
            setSubjects([]); // Set to empty array if data is not in expected format
          }
        })
        .catch(error => {
          console.error('Error fetching subjects:', error);
          setSubjects([]); // Handle error by setting to empty array
        });
    }
  }, [newClass.course_id, instituteId]);


  // const handleClassChange = (e) => {
  //   const { name, value } = e.target;
  //   setNewClass(prevClass => ({
  //     ...prevClass,
  //     [name]: value,
  //   }));
  // };


  const handleClassChange = async (e) => {
    const { name, value } = e.target;

    let updatedNewClass = {
      ...newClass,
      [name]: value,
    };
    console.log(name, value)
    // Fetch and update names based on selection
    if (name === 'course_id') {
      const selectedCourse = courses.find(course => course.course_id == value);
      updatedNewClass = {
        ...updatedNewClass,
        course_name: selectedCourse ? selectedCourse.course_name : ''
      };
    } else if (name === 'batch_id') {
      const selectedBatch = batches.find(batch => batch.batch_id == value);
      updatedNewClass = {
        ...updatedNewClass,
        batch_name: selectedBatch ? selectedBatch.batch_name : ''
      };
    } else if (name === 'subject_id') {
      const selectedSubject = subjects.find(subject => subject.subject_id == value);
      updatedNewClass = {
        ...updatedNewClass,
        subject_name: selectedSubject ? selectedSubject.subject_name : ''
      };
    }

    setNewClass(updatedNewClass);
  };



  const addClass = () => {
    const { course_id, batch_id, subject_id, course_name, batch_name, subject_name } = newClass;

    // Check if all necessary fields are filled
    if (!course_id || !batch_id || !subject_id) {
      console.error('Please fill in all fields before adding a class.');
      return;
    }
    const isDuplicate = classList.some(cls =>
      cls.course_id === course_id && cls.batch_id === batch_id && cls.subject_id === subject_id
    );
    if (isDuplicate) {
      showToast("This class already exists in the list.");
      return;
    }

    const newClassItem = {
      class_id: Date.now(), // Unique ID
      course_id,
      batch_id,
      subject_id,
      course_name, // Use course_name from state
      batch_name,  // Use batch_name from state
      subject_name // Use subject_name from state
    };

    setClassList(prevList => [...prevList, newClassItem]);
    // setNewClass({
    //   course_id: '',
    //   batch_id: '',
    //   subject_id: '',
    //   class_id: '',
    //   course_name: '',
    //   batch_name: '',
    //   subject_name: ''
    // }); 
    // Reset new class input

    console.log('Added new class:', newClassItem);
  };
  const clearData = async () => {
    try {
      const updatesearchteacher = {
        phone: "",
        name: "",
        usercode: "",
        usertypeid: 2,
      };
      const updatesearchteacherAPI = {
        phone: "",
        name: "",
        usercode: "",
        usertypeid: 2,
      };

      const updateteacher = {
        phone: "",
        first_name: "",
        last_name: "",
        role_id: "",
        title: "",
        email: "",
        institute_id: "",
        new_class: [],
        prev_class: [],
      };

      updateState("searchteacher", updatesearchteacher);
      updateState("teacher", updateteacher);
      updateState("searchteacherAPI", updatesearchteacherAPI);

      const updatedapifieldsState = {
        designation_name: "",
        is_active: true
      };
      const updatedfieldState = {
        designation_name: "",
        is_active: true
      };
      //console.log(state.apifields)
      updateState("oldfields", state.apifields);
      updateState("apifields", updatedapifieldsState);
      updateState("fields", updatedfieldState);
    } catch (err) { }
  };

  const deleteClass = (classId) => {
    setClassList(prevList => prevList.filter(cls => cls.class_id !== classId));
    // setPrevClasses(prevList => prevList.filter(cls => cls.class_id !== classId));
  };

  const saveChanges = async (userId) => {
    const newClasses = classList.filter(cls => !prevClasses.some(prev => prev.class_id === cls.class_id));
    const removedClasses = prevClasses.filter(prev => !classList.some(cls => cls.class_id === prev.class_id));

    let newapiFields = {
      ...userDetails,
      new_class: newClasses,
      prev_class: removedClasses,
    }
    const editData = async () => {
      try {
        const result = await putAPI(`admin/appuser/teacher/${userId}`, newapiFields, false);
        if (result && result.statusCode === 200 && result.success === true) {
          showToast("Record has been updated successfully.");
          clearData();
          updateState("saved", false);
          updateState("is_edit", false);
          setShowBelowCode(false);
          setSelectionCode(false);
          setShowTransactionCheckout(false);
        } else {
          showToast("Something went wrong. Please try again later.");
        }
      } catch (err) {

      }
    };
    editData();

  };
  const handleBlockUnblock = async (userId, blockStatus) => {
    try {
      // const newStatus = !currentStatus; // Toggle the status
      let newapiFields = {
        is_block: blockStatus
      }
      const editData = async () => {
        try {
          const result = await putAPI(`admin/appuser/blockstatus/${userId}`, newapiFields, false);
          if (result && result.statusCode === 200 && result.success === true) {

            updateState("saved", false);
            updateState("is_edit", false);
            tbldatateacher.data.userlist = tbldatateacher.data.userlist.map(user =>
              user.user_id == userId
                ? { ...user, is_block: blockStatus }
                : user
            );
            showToast("Record has been updated successfully.");
            // clearData();
          } else {
            showToast("Something went wrong. Please try again later.");
          }
        } catch (err) {

        }
      };
      editData();
    } catch (error) {
      console.error('Error:', error);
      showToast('Error updating block status.');
    }
  };



  // let tblapiuserinfo = {};







  //#endregion


  return (
    <>
      <style>
        {`
      
    
          .custom-cursor { 
    --cursor-width: 0px !important;
    --color: #fff;
    --blending-mode: darkcyan;
    cursor: default;
          }
    .form-control:focus {
    color: #495057;
    background-color: #fff;
    border-color: #000000;
    outline: 0;
    box-shadow: none !important;
}
    .form-control {
    
    font-size: 14px !important;
        }
    body{
        color: #2e314a !important;}
        `}
      </style>
      <div className="flex min-h-screen w-full flex-col justify-between">
        <div className="flex w-full flex-col justify-center px-5">
          <div className="mx-auto w-full max-w-md md:max-w-lg lg:max-w-xl 2xl:pb-8 2xl:pt-2 paddingtop1">
            <div className="flex flex-col items-center">
              <div className="maindiv">
                <a className="displayinlineblock">
                  <img alt="FriendsupAdmin" fetchPriority="high" width="155" height="28" decoding="async" data-nimg="1" className="dark:invert logocss" src={logoimage} />
                  <span className="booklogo"> Friendsup</span>
                </a>
                <div className="right-float">
                  <a onClick={handleLogout} className="nk-menu-link nk-menu-link1 bottomname nk-menu-link-custom pointercursor">
                    <div className="media media-md media-circle media-margin-right">
                      {/* <img src={userimage} alt="" className="img-thumbnail" /> */}
                      <img src={state.imgSrc
                        ? `${baseURL.smallImages}${state.imgSrc}`
                        : `${baseURL.smallImages}friendsupprofile.jpg`
                      } alt="Profile" className="img-thumbnail" />
                    </div>
                    <span className="bottomtitle">
                      <span className="nk-menu-text">{state.user_name}</span>
                      <span className="nk-menu-text font-size12 underline-text">Click to logout</span>
                    </span>
                  </a>
                </div>
              </div>

              <div className="custom-wrapper">
                <div className="custom-border-1">
                  <div className={activeTab === 'findUser' ? 'custom-button-1 active' : 'custom-button-2'} onClick={() => handleTabClick('findUser')}>
                    <span className="custom-text-1">Teacher / Student Information</span>
                  </div>
                  <div className={activeTab === 'classList' ? 'custom-button-1 active' : 'custom-button-2'} onClick={() => handleTabClick('classList')}>
                    <span className="custom-text-2">Class Information</span>
                  </div>
                  <div className={activeTab === 'findStudent' ? 'custom-button-1 active' : 'custom-button-2'} onClick={() => handleTabClick('findStudent')}>
                    <span className="custom-text-3">Send Messages</span>
                  </div>
                </div>
              </div>

              <div className="custom-wrapper">
                <div className="custom-content">
                  {/* Content for each tab */}
                  {activeTab === 'findUser' &&
                    <div>

                      {!showBelowCode && (
                        <>
                          <div className="custom-title">
                            <span>

                              Type a phone number, name, or user code to find a specific teacher or student.
                              Use the search criteria to quickly locate and manage records.


                            </span>
                          </div>
                          <div>
                            <div className="input-container">
                              <input type="text" name="phone" maxLength={10} placeholder="Phone number" className="form-control txtbox"
                                value={state.searchteacher.phone}
                                onChange={handleInputChangeNumberSearch} />
                              <input type="text" name="name" placeholder="Name" className="form-control txtbox"
                                value={state.searchteacher.name}
                                onChange={handleInputChangeSearch} />
                              <input type="text" name="usercode" maxLength={6} placeholder="User code" className="form-control txtbox"
                                value={state.searchteacher.usercode}
                                onChange={handleInputChangeNumberSearch} />
                              <button
                                type="button"
                                className="custom-enroll searchbtn"
                                onClick={applyFilter}
                              >Search</button>
                            </div>
                            <div className="input-container">
                              <label>
                                <input
                                  type="radio"
                                  name="usertype"
                                  className="rdobtn"
                                  value="2"
                                  checked={state.searchteacher.usertypeid == '2'}
                                  onChange={handleRadioChange}
                                />
                                Display 25 teacher
                              </label>
                              <label>
                                <input
                                  type="radio"
                                  name="usertype"
                                  className="rdobtn"
                                  value="3"
                                  checked={state.searchteacher.usertypeid == '3'}
                                  onChange={handleRadioChange}
                                />
                                Display 25 student
                              </label>
                            </div>
                          </div>


                          {tbldatateacher &&
                            (!tbldatateacher.data || !tbldatateacher.data.userlist?.length) ? (
                            <>
                              <div className="norecordcss">
                                <img
                                  src={emptyrecord}
                                  width="330px"
                                  alt="No record found"
                                />
                                <div className="norecordfont">No record found</div>
                              </div>
                            </>
                          ) : (
                            <>

                              {tbldatateacher &&
                                tbldatateacher.data.userlist.map((user) => (
                                  <div
                                    key={user.user_id}
                                    onClick={() => handleUserClick(user.user_id)}
                                    className="custom-coursej"
                                  >
                                    <div className="custom-image">
                                      <img
                                        src={
                                          user.profile_small
                                            ? `${baseURL.smallImages}${user.profile_small}`
                                            : `${baseURL.smallImages}friendsupprofile.jpg`
                                        }
                                        alt="Profile"
                                        className="img-thumbnail imgtagsj custom-image1"
                                      />
                                    </div>

                                    <div className="custom-details-wrapper">
                                      <div className="custom-details">
                                        <span className="custom-course-title">
                                          {user.first_name} {user.last_name}
                                          <img
                                            className="badgecss"
                                            src={badgesvg}
                                            alt="Badge"
                                            style={setSvgColor(user.badge.badge_color)}
                                          />
                                        </span>
                                        <span className="custom-course-description"> {user.role_id === 2 ? 'Teacher' : 'Student'}</span>
                                        <div className="custom-buttonsj">
                                          <span className="custom-tag">ID : {user.user_code}</span>
                                          <span className="custom-tag left5">Password : {user.temppassword}</span>

                                          <span>
                                            <button
                                              className={`custom-enrollblock ${user.is_block ? 'unblock-button' : 'block-button'}`}
                                              onClick={(e) => {
                                                e.stopPropagation(); // Prevent triggering handleUserClick
                                                // Call function to handle block/unblock action
                                                handleBlockUnblock(user.user_id, !user.is_block);
                                              }}
                                            >
                                              {user.is_block ? 'Unblock' : 'Block'}
                                            </button>
                                          </span>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                ))}


                              {/* {tbldatateacher &&
                                tbldatateacher.data.courses.map((x) => (
                                  <>

                                    <div key={x.user_id} onClick={() => handleUserClick(x.user_id)} className="custom-coursej">
                                      <div className="custom-image">
                                        <img src="assets/images/userimg.jpg" alt="" className="img-thumbnail imgtagsj">
                                      </div>
                                      <div className="custom-details">
                                        <span className="custom-course-title">Fenil Donda <img className="badgecss" src="assets/images/badge.svg" /></span>

                                        <span className="custom-course-description">Teacher</span>
                                        <div className="custom-buttonsj">
                                          <span className="custom-tag">ID : 381238</span>
                                          <span className="custom-tag">Password : 23232452</span>
                                          <span className="custom-enroll">Edit Now</span>
                                        </div>
                                      </div>
                                    </div>


                                  </>
                                ))} */}
                            </>
                          )}
                        </>
                      )}




                      {showBelowCode && (
                        <>

                          <div className="custom-title width100">
                            <span className="maintitle">You can update teachers' or students' information, including their name, phone number, and current class details, and manage class associations by adding or removing classes.</span>
                            <span className="cancleicon " onClick={handleCancelIconClick}>x</span>
                          </div>



                          <div>
                            <div className="margin20">
                              <div className="displayflex">
                                <div>
                                  <label className="displayblock">
                                    First Name </label>
                                  <input
                                    type="text"
                                    name="first_name"
                                    className="form-control txtbox"
                                    value={userDetails.first_name || ''}
                                    onChange={handleInputChangef}
                                  />
                                </div>
                                <div>

                                  <label className="displayblock">
                                    Last Name</label>
                                  <input
                                    type="text"
                                    className="form-control txtbox"
                                    name="last_name"
                                    value={userDetails.last_name || ''}
                                    onChange={handleInputChangef}
                                  />
                                </div>
                                <div>
                                  <label className="displayblock">
                                    Phone </label>
                                  <input
                                    type="text"
                                    name="phone"
                                    className="form-control txtbox"
                                    value={userDetails.phone || ''}
                                    onChange={handleInputChangeNumberf}
                                  />
                                </div>
                                <div>
                                  <label className="displayblock">
                                    Email  </label>
                                  <input
                                    type="email"
                                    name="email"
                                    className="form-control txtbox"
                                    value={userDetails.email || ''}
                                    onChange={handleInputChangef}
                                  />

                                </div>


                              </div>


                              {/* Add other fields as needed */}
                            </div>

                            <div className="margin20">
                              <div className="custom-title">
                                <span>Manage Classes</span>
                              </div>
                              <div className="displayflex">
                                <div>
                                  <label className="displayblock">
                                    Course
                                  </label>


                                  <Select2
                                    data={
                                      Array.isArray(courses) ?
                                        courses.map(course => ({
                                          text: course.course_name,
                                          id: course.course_id,
                                          key: course.course_name.toString(), // Optional: Unique key for React
                                        }))
                                        : []
                                    }
                                    options={{
                                      placeholder: "Select Course",
                                      width: '100%' // Optional: Adjust width if needed
                                    }}
                                    name="course_id"
                                    className="form-control"
                                    value={newClass.course_id}
                                    onChange={handleClassChange}
                                  // Adjust the onChange if needed to handle Select2's value format
                                  />
                                  {/* <select name="course_id" value={newClass.course_id} onChange={handleClassChange}>
                                  <option value="">Select Course</option>
                                  {Array.isArray(courses) && courses.length > 0 ? (
                                    courses.map(course => (
                                      <option key={course.course_id} value={course.course_id}>
                                        {course.course_name}
                                      </option>
                                    ))
                                  ) : (
                                    <option disabled>No courses available</option>
                                  )}
                                </select> */}
                                </div>
                                <div>
                                  <label className="displayblock">

                                    Batch

                                  </label>

                                  <Select2
                                    data={
                                      Array.isArray(batches) ?
                                        batches.map(batch => ({
                                          text: batch.batch_name,
                                          id: batch.batch_id,
                                          key: batch.batch_name.toString(), // Optional: Unique key for React
                                        }))
                                        : []
                                    }
                                    options={{
                                      placeholder: "Select Batch",
                                      width: '100%' // Optional: Adjust width if needed
                                    }}
                                    name="batch_id"
                                    className="form-control"
                                    value={newClass.batch_id}
                                    onChange={handleClassChange}
                                  // Adjust the onChange if needed to handle Select2's value format
                                  />
                                </div>
                                <div>
                                  <label className="displayblock">

                                    Subject
                                  </label>

                                  <Select2
                                    data={
                                      Array.isArray(subjects) ?
                                        subjects.map(subject => ({
                                          text: subject.subject_name,
                                          id: subject.subject_id,
                                          key: subject.subject_name.toString(), // Optional: Unique key for React
                                        }))
                                        : []
                                    }
                                    options={{
                                      placeholder: "Select Subject",
                                      width: '100%' // Optional: Adjust width if needed
                                    }}
                                    name="subject_id"
                                    className="form-control"
                                    value={newClass.subject_id}
                                    onChange={handleClassChange}
                                  // Adjust the onChange if needed to handle Select2's value format
                                  />
                                </div>
                                <div>
                                  <label className="displayblock">

                                    &nbsp;
                                  </label>
                                  <button className="custom-enroll searchbtn height29"
                                    onClick={addClass}>Add Class</button>

                                </div>
                              </div>
                              <div className="margin20">
                              </div>
                              <div className="custom-title">
                                <span>Class List</span>
                              </div>
                              <div class="tags-container">
                                {classList.map(cls => (
                                  <div key={cls.class_id} class="tag-item">
                                    <span class="tag-text">
                                      {`${cls.course_name}-${cls.batch_name}-${cls.subject_name}`}
                                    </span>
                                    <button
                                      class="tag-delete-button"
                                      onClick={() => deleteClass(cls.class_id)}
                                    >
                                      X
                                    </button>
                                  </div>
                                ))}
                              </div>
                            </div>
                            <ul>
                              {/* {classList.map(cls => (
                                <li key={cls.class_id}>
                                  {`Class ID: ${cls.class_id}, Course: ${cls.course_name}, Batch: ${cls.batch_name}, Subject: ${cls.subject_name}`}
                                  <button className="custom-enroll searchbtn"
                                    onClick={() => deleteClass(cls.class_id)}>Delete</button>
                                </li>
                              ))} */}

                            </ul>

                            <button className="custom-enroll searchbtn"
                              onClick={() => saveChanges(userDetails.user_id)}>Save Changes</button>
                            {/* <button onClick={saveChanges(userDetails.user_id)}>Save Changes</button> */}
                          </div>
                        </>
                      )}


                    </div>
                  }
                  {activeTab === 'classList' &&
                    <div>
                      <div>

                        <div className="custom-title">
                          <span>      You can create new classes or edit existing class information.
                            Set class teachers and manage class details. Coming soon!

                          </span>
                        </div>
                        <div className="norecordcss">
                          <img
                            src={emptyrecord}
                            width="330px"
                            alt="No record found"
                          />
                          <div className="norecordfont">No record found</div>
                        </div>
                      </div>

                    </div>
                  }
                  {activeTab === 'findStudent' &&
                    <div>

                      <div className="custom-title">
                        <span> You can send important messages to students or parents by selecting a class or the entire institute.
                          SMS and WhatsApp messaging features will be available soon.
                        </span>
                      </div>

                      <div className="norecordcss">
                        <img
                          src={emptyrecord}
                          width="330px"
                          alt="No record found"
                        />
                        <div className="norecordfont">No record found</div>
                      </div>


                    </div>
                  }
                </div>
              </div>





            </div>

          </div>
        </div>

      </div >
      <div className="bottomfix ">
        © 2024 Friendsup by #Galaxy Developers. All rights reserved.
      </div>
      {/* ====================================================== Toast message ====================================================== */}
      {
        state.show_toast && (
          <div
            className="toast align-items-center text-bg-primary border-0 fade show toastmsg"
            role="alert"
            aria-live="assertive"
            aria-atomic="true"
          >
            <div className="d-flex">
              <div className="toast-body">{state.toast_msg}</div>
              <button
                type="button"
                className="btn-close btn-close-white me-2 m-auto"
                data-bs-dismiss="toast"
                onClick={(e) => hideToast()}
                aria-label="Close"
              ></button>
            </div>
          </div>
        )
      }


    </>
  );
};

export default authLayout(BookSlot);
